export interface RRuleOptions {
  freq: RRuleFreq;
  count: number;
  interval: number;
}

export enum RRuleFreq {
  EMPTY = 'EMPTY',
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}
