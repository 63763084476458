import { Component, OnInit } from '@angular/core';
import { BatchScan } from 'src/app/models/batch-scan.interface';
import { Dentist } from 'src/app/models/dentist.interface';
import {
  TaskLocationStatus,
  TaskStatus,
} from 'src/app/models/task-status.enum';
import { Task } from 'src/app/models/task.interface';
import { TaskDate } from 'src/app/models/taskDate.interface';
import { Technician } from 'src/app/models/technician.interface';
import { DataService } from 'src/app/services/data.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-task-content',
  templateUrl: './task-content.component.html',
  styleUrls: ['./task-content.component.scss'],
})
export class TaskContentComponent implements OnInit {
  tasks: Task[] = [];
  technicians: Technician[] = [];
  dates: TaskDate[] = [];
  dentists: Dentist[] = [];

  batchScans: BatchScan[] = [];
  batchScanModal = false;

  addModal: boolean;
  patchModal: boolean;

  pageIndex: number;
  pageSize = 20;
  lastPage;
  selectedRows: any[];
  selected;
  taskDetail: Task;
  detailModal = false;
  deActivateModal = false;

  search: string;
  searching = false;
  searchButtonDisabled = true;
  searchResults: Task[] = [];

  constructor(private data: DataService, private rest: RestService) {}

  ngOnInit(): void {
    this.data.refreshTaskDates().then();
    this.data.currentTaskDate.subscribe((v) => (this.dates = v));
    this.data.refreshDentists().then();
    this.data.currentDentists.subscribe((v) => (this.dentists = v));
    this.data.refreshTasks().then();
    this.data.currentTasks.subscribe((v) => (this.tasks = v));
    this.data.refreshTechnicians().then();
    this.data.currentTechnicians.subscribe((v) => (this.technicians = v));
  }

  async detailTask(taskId: number): Promise<void> {
    this.detailModal = true;
    this.taskDetail = await this.data.getSingleTaskById(taskId);
  }

  async getBatchScansByTaskId(id: number): Promise<void> {
    this.batchScans = await this.data.getBatchScansByTaskId(id);
  }

  async openBatchScanModal(task: Task): Promise<void> {
    this.batchScanModal = true;
    this.taskDetail = task;
    await this.getBatchScansByTaskId(task.id);
  }

  async searchTask(search: string): Promise<void> {
    console.log(search);
    if (search) {
      if (search.length > 1) {
        this.searchButtonDisabled = false;
        this.search = search;
        await this.getTaskSearchData(search);
      } else {
        this.searchResults = [];
        this.searchButtonDisabled = true;
      }
    } else {
      this.searchResults = [];
    }
  }

  async getTaskSearchData(search: string): Promise<void> {
    this.searching = true;
    this.searchResults = await this.rest.getTaskSearch(search);
    this.searching = false;
  }

  getTechnicianNameById(id: number): string {
    const technician = this.technicians?.find((t) => t.id === +id);
    return technician?.firstName;
  }

  async updateTask(): Promise<void> {
    await this.data.updateTask(this.taskDetail);
    this.taskDetail = null;
  }

  transformString(value: any): any {
    if (!this.search) {return value; }
    const re = new RegExp(this.search, 'gi'); // 'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    return value.replace(re, '<mark>$&</mark>');
  }

  async updateTaskLocationStatus(task: Task): Promise<void> {
    const newTask = task;
    if (newTask.locationStatus === TaskLocationStatus.INHOUSE) {
      newTask.locationStatus = TaskLocationStatus.NOTINHOUSE;
    } else {
      newTask.locationStatus = TaskLocationStatus.INHOUSE;
    }
    await this.data.updateTask(newTask);
  }

  async openPatchModal(task: Task): Promise<void> {
    this.taskDetail = await this.data.getSingleTaskById(task.id);
    this.patchModal = true;
  }

  async openDeActivateModal(task: Task): Promise<void> {
    this.taskDetail = await this.data.getSingleTaskById(task.id);
    this.deActivateModal = true;
  }

  deActivateTask(): void {
    this.taskDetail.status = TaskStatus.INACTIVE;
    this.data.updateTask(this.taskDetail).then();
    this.deActivateModal = false;
  }

  deActivateSingleTask(task: Task): void {
    const newTask = task;
    newTask.status = TaskStatus.INACTIVE;
    this.data.updateTask(newTask).then();
  }

  activateTask(task: Task): void {
    const newTask = task;
    newTask.status = TaskStatus.ACTIVE;
    this.data.updateTask(newTask).then();
  }

  async createTask(
    uid: string,
    description: string,
    patient: string,
    dentistId: number,
    taskOwnerId: number
  ): Promise<void> {
    await this.data.createTask(
      uid,
      description,
      patient,
      dentistId,
      taskOwnerId
    );
    this.addModal = false;
  }
}
