<div class="clr-row clr-justify-content-between">
    <div class="clr-col-12 clr-align-self-center">
        <h3 class="pa-5">
            Kalender Einstellungen
        </h3>
        <button (click)="addModal = !addModal" class="btn btn-sm">Neue Kalender Kategorie
            hinzufügen</button>



        <clr-datagrid>
            <clr-dg-column [clrDgField]="'statusDescription'">Beschreibung</clr-dg-column>
            <clr-dg-column>Farb-Bezeichnung</clr-dg-column>
            <clr-dg-column>Farbe</clr-dg-column>
            <clr-dg-column>Schrift-Farb-Bezeichnung</clr-dg-column>
            <clr-dg-column>Schrift-Farbe</clr-dg-column>

            <clr-dg-row (click)="openPatchModal(status)" *clrDgItems="let status of calenderStatus">
                <clr-dg-cell>{{status.statusDescription}}</clr-dg-cell>
                <clr-dg-cell>{{status.color}}</clr-dg-cell>
                <clr-dg-cell>
                    <div [ngStyle]="{'background': status.color}"
                        style="border-radius: 7px; width: 14px; height: 14px; margin-top: 5px; margin-left: 15px;">
                    </div>
                </clr-dg-cell>
                <clr-dg-cell>{{status.fontColor}}</clr-dg-cell>
                <clr-dg-cell>
                    <div [ngStyle]="{'background': status.fontColor}"
                        style="border-radius: 7px; width: 14px; height: 14px; margin-top: 5px; margin-left: 15px;">
                    </div>
                </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
                <clr-dg-pagination #pagination2 [clrDgTotalItems]="calenderStatus.length" [(clrDgPage)]="pageIndex"
                    [clrDgPageSize]="pageSize">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]">Kalender Statuse pro Seite
                    </clr-dg-page-size>
                    {{pagination2.firstItem + 1}} - {{pagination2.lastItem + 1}}
                    von {{pagination2.totalItems}} Arbeiten
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
        <h3>Allgmeine Termine</h3>
        <button (click)="calenderAddModal = !calenderAddModal" class="btn btn-sm">Neuer Tagestermin</button>
        <clr-datagrid>
            <clr-dg-column [clrDgField]="'description'">Beschreibung</clr-dg-column>
            <clr-dg-column>Von</clr-dg-column>
            <clr-dg-column>Bis</clr-dg-column>
            <clr-dg-column>Farbe</clr-dg-column>
            <clr-dg-column>Sonderregel</clr-dg-column>
            <clr-dg-column>Notiz</clr-dg-column>
         

            <clr-dg-row (click)="openCalenderDatePatchModal(status)" *clrDgItems="let status of calenderDateRange">
                <clr-dg-cell>{{status.description}}</clr-dg-cell>
                <clr-dg-cell>{{status.from | date}}</clr-dg-cell>
                <clr-dg-cell>{{status.to | date}}</clr-dg-cell>
                <clr-dg-cell>
                    <div [ngStyle]="{'background': status.color}"
                        style="border-radius: 7px; width: 14px; height: 14px; margin-top: 5px; margin-left: 15px;">
                    </div>
                </clr-dg-cell>
                <clr-dg-cell>{{ rRuleStringToText(status.rRule) }}</clr-dg-cell>
                <clr-dg-cell>{{status.notes}}</clr-dg-cell>
            </clr-dg-row>
        <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgTotalItems]="calenderDateRange.length" [(clrDgPage)]="pageIndexDate"
                    [clrDgPageSize]="pageSizeDate">
                    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]">Termine pro Seite
                    </clr-dg-page-size>
                    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                    von {{pagination.totalItems}} Termine
                </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>
    </div>

    <clr-modal [(clrModalOpen)]="addModal" [clrModalClosable]="false">
        <h3 class="modal-title">Neue Katergorie hinzufügen</h3>
        <div class="modal-body">
            <div class="clr-row">
                <div class="clr-col-4">
                    <clr-input-container>
                        <label>Kalender-Status-Beschreibung</label>
                        <input clrInput type="text" [(ngModel)]="newCalenderStatusData.statusDescription" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Farbe</label>
                        <input clrInput type="color" [(ngModel)]="newCalenderStatusData.color" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Schrift-Farbe</label>
                        <input clrInput type="color" value="#fff" [(ngModel)]="newCalenderStatusData.fontColor" />
                    </clr-input-container>
                </div>
                <div class="clr-col-4 clr-align-content-center">
                    <div [ngStyle]="{'background-color': newCalenderStatusData.color}" style="border-radius: 15px;">
                        <p [ngStyle]="{'color': newCalenderStatusData.fontColor}" style="padding: 25px;">Hallo!</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button (click)="addModal = !addModal" class="btn btn-outline" type="button">Zurück</button>
            <button (click)="createCalenderStatus()" class="btn btn-primary" type="button">Speichern</button>
        </div>
    </clr-modal>

    <div *ngIf="detailData">
        <clr-modal [(clrModalOpen)]="patchModal" [clrModalClosable]="false">
            <h3 class="modal-title">{{ detailData.statusDescription }} bearbeiten<button
                (click)="deleteCalenderStatus(detailData.id)" class="btn btn-warning btn-sm">Löschen</button></h3>
            <div class="modal-body">
                <div class="clr-row">
                    <div class="clr-col-4">
                        <clr-input-container>
                            <label>Kalender-Status-Beschreibung</label>
                            <input clrInput name="name" value="{{ detailData.statusDescription }}"
                                [(ngModel)]="detailData.statusDescription" />
                        </clr-input-container>
                        <clr-input-container>
                            <label>Farbe</label>
                            <label>{{detailData.color}}</label>
                            <input clrInput type="color" value="{{ detailData.color }}"
                                [(ngModel)]="detailData.color" />
                        </clr-input-container>

                        <clr-input-container>
                            <label>Schrift-Farbe</label>
                            <label>{{detailData.fontColor}}</label>
                            <input clrInput type="color" value="{{ detailData.fontColor }}"
                                [(ngModel)]="detailData.fontColor" />
                        </clr-input-container>
                    </div>
                    <div class="clr-col-4 clr-align-content-center">
                        <div [ngStyle]="{'background-color': detailData.color}" style="border-radius: 15px;">
                            <p [ngStyle]="{'color': detailData.fontColor}" style="padding: 25px;">Hallo!</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button (click)="patchModal = !patchModal" class="btn btn-outline" type="button">Zurück</button>
                <button (click)="updateCalenderStatus()" class="btn btn-primary" type="button">Speichern</button>
            </div>
        </clr-modal>
    </div>

    <clr-modal [(clrModalOpen)]="calenderAddModal" [clrModalClosable]="false">
        <h3 class="modal-title">Neue Termin hinzufügen</h3>
        <div class="modal-body">
            <clr-input-container>
                <label>Kalender-Termin-Beschreibung</label>
                <input clrInput name="name" #desc />
            </clr-input-container>
            <clr-date-container>
                <label>Von</label>
                <input type="date" clrDate name="demo" #from>
            </clr-date-container>
            <clr-date-container>
                <label>Bis</label>
                <input type="date" clrDate name="demo" #to>
            </clr-date-container>
            <clr-input-container>
                <label>Farbe</label>
                <input clrInput type="color" value="#000000" #color />
            </clr-input-container>
            <clr-textarea-container>
                <label>Notiz</label>
                <textarea clrTextarea name="description"  [(ngModel)]="description"  #note></textarea>
              </clr-textarea-container>
            <br>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox name="options" required value="option1" [(ngModel)]="rRuleForm" />
                <label>Regel erstellen</label>
            </clr-checkbox-wrapper>
            <div *ngIf="rRuleForm" class="clr-row">
                <div class="clr-col-12">
                    <h5>{{ rRuleOptionsToText(rRuleOptions) }}</h5>
                </div>
                <div class="clr-col-4">
                    <clr-radio-container>
                        <label>Wiederholung</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="EMPTY"
                                [(ngModel)]="rRuleOptions.freq" />
                            <label>Keine</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="DAILY"
                                [(ngModel)]="rRuleOptions.freq" />
                            <label>Täglich</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="WEEKLY"
                                [(ngModel)]="rRuleOptions.freq" />
                            <label>Wöchentlich</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="MONTHLY"
                                [(ngModel)]="rRuleOptions.freq" />
                            <label>Monatlich</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="options" required value="YEARLY"
                                [(ngModel)]="rRuleOptions.freq" />
                            <label>Jährlich</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                </div>
                <div class="clr-col-4">
                    <clr-input-container>
                        <label>Wiederholungen</label>
                        <input clrInput type="number" min="0" max="24" [(ngModel)]="rRuleOptions.count" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>Interval</label>
                        <input clrInput type="number" min="0" max="31" [(ngModel)]="rRuleOptions.interval" />
                    </clr-input-container>
                </div>
                <div class="clr-col-4">

                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button (click)="calenderAddModal = !calenderAddModal" class="btn btn-outline" type="button">Zurück</button>
            <button (click)="createCalenderRangeDate(from.value,to.value, color.value, desc.value)"
                class="btn btn-primary" type="button">Speichern</button>
        </div>
    </clr-modal>

    <div *ngIf="detailDataCalender">
        <clr-modal [(clrModalOpen)]="calenderPatchModal" [clrModalClosable]="false">
            <h3 class="modal-title">{{ detailDataCalender.description }} bearbeiten <button
                    (click)="deleteCalenderDate(detailDataCalender.id)" class="btn btn-warning btn-sm">Löschen</button>
            </h3>
            <div class="modal-body">
                <clr-input-container>
                    <label>Kalender-Termin-Beschreibung</label>
                    <input clrInput name="name" value="{{ detailDataCalender.description }}"
                        [(ngModel)]="detailDataCalender.description" />
                </clr-input-container>
                <clr-date-container>
                    <label>Von</label>
                    <input type="date" clrDate name="demo" (change)="updateFromDate($event)" [(clrDate)]="fromDate">
                </clr-date-container>
                <clr-date-container>
                    <label>Bis</label>
                    <input type="date" clrDate name="demo" (change)="updateToDate($event)" [(clrDate)]="toDate" />
                </clr-date-container>
                <clr-input-container>
                    <label>Farbe</label>
                    <label>{{detailDataCalender.color}}</label>
                    <input clrInput type="color" value="{{ detailDataCalender.color }}"
                        [(ngModel)]="detailDataCalender.color" />
                </clr-input-container>
                <clr-textarea-container>
                    <label>Notiz</label>
                    <textarea clrTextarea name="description" value="{{ detailDataCalender.notes }}" [(ngModel)]="detailDataCalender.notes"></textarea>
                  </clr-textarea-container>
                <br>
                <clr-checkbox-wrapper>
                    <input type="checkbox" clrCheckbox name="options" required value="option1"
                        [(ngModel)]="rRuleForm" />
                    <label>Regel erstellen</label>
                </clr-checkbox-wrapper>
                <div *ngIf="rRuleForm" class="clr-row">
                    <div class="clr-col-12">
                        <h5>{{ rRuleOptionsToText(rRuleOptions) }}</h5>
                    </div>
                    <div class="clr-col-4">
                        <clr-radio-container>
                            <label>Wiederholung</label>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value="EMPTY"
                                    [(ngModel)]="rRuleOptions.freq" />
                                <label>Keine</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value="DAILY"
                                    [(ngModel)]="rRuleOptions.freq" />
                                <label>Täglich</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value="WEEKLY"
                                    [(ngModel)]="rRuleOptions.freq" />
                                <label>Wöchentlich</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value="MONTHLY"
                                    [(ngModel)]="rRuleOptions.freq" />
                                <label>Monatlich</label>
                            </clr-radio-wrapper>
                            <clr-radio-wrapper>
                                <input type="radio" clrRadio name="options" required value="YEARLY"
                                    [(ngModel)]="rRuleOptions.freq" />
                                <label>Jährlich</label>
                            </clr-radio-wrapper>
                        </clr-radio-container>
                    </div>
                    <div class="clr-col-4">
                        <clr-input-container>
                            <label>Wiederholungen</label>
                            <input clrInput type="number" min="0" max="24" [(ngModel)]="rRuleOptions.count" />
                        </clr-input-container>
                        <clr-input-container>
                            <label>Interval</label>
                            <input clrInput type="number" min="0" max="31" [(ngModel)]="rRuleOptions.interval" />
                        </clr-input-container>
                    </div>
                    <div class="clr-col-4">

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="calenderPatchModal = !calenderPatchModal" class="btn btn-outline"
                    type="button">Zurück</button>
                <button (click)="updateCalenderDate()" class="btn btn-primary" type="button">Speichern</button>
            </div>
        </clr-modal>
    </div>