import { NgSwitchCase } from '@angular/common';
import { Injectable } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { WebSocketResponse } from '../models/websocket-res.interface';
import { CRUD, UpdateCategory } from '../models/ws.category.enum';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { WsNotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  socket: any = null;

  constructor(
    private wsNotifications: WsNotificationService,
    private auth: AuthService,
  ) { }


  startSocket(): void {
    this.socket = io(environment.APIEndpoint);
    this.socket.on('msgToClient', (msg: any) => {
      this.receiveMessage(msg);
    });
    this.sendMessage();

  }

  sendMessage(): void {
    const token = this.auth.getLocalStorageToken();
    const decode = this.auth.decode(token);

    this.socket.emit('msgToServer', { room: decode.id.toString() });
  }

  receiveMessage(msg: string): void {

    const webSocketResponse: WebSocketResponse = JSON.parse(msg);

    this.wsNotifications.refreshDataAndSendNotification(webSocketResponse).then();
  }

}
