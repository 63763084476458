<h1>Aufträge</h1>
<clr-tabs>
    <clr-tab>
        <button clrTabLink>Aktuelle Aufträge</button>
        <clr-tab-content>
            <h2 class="pa-5">
                Arbeiten
            </h2>
            <a routerLink="../addTask" class="btn btn-sm">Neue Aufgaben
                hinzufügen</a>
            <clr-datagrid>
                <clr-dg-column>Status</clr-dg-column>
                <clr-dg-column>Standort</clr-dg-column>
                <clr-dg-column>UID</clr-dg-column>
                <clr-dg-column>Beschreibung</clr-dg-column>
                <clr-dg-column>Zahnarzt</clr-dg-column>
                <clr-dg-column>Patient</clr-dg-column>
                <clr-dg-column>Techniker</clr-dg-column>
                <clr-dg-column>Erstellungsdatum</clr-dg-column>

                <clr-dg-row *clrDgItems="let task of tasks">

                    <clr-dg-cell>
                        <clr-icon style="margin: 5px;" (click)="detailTask(task.id)" shape="info-standard"></clr-icon>
                        <clr-icon style="margin: 5px;" (click)="openPatchModal(task)" shape="edit"></clr-icon>
                        <clr-icon style="margin: 5px;" (click)="openDeActivateModal(task)" shape="close"></clr-icon>
                        <clr-icon style="margin: 5px;" (click)="openBatchScanModal(task)" shape="clipboard"></clr-icon>
                    </clr-dg-cell>
                    <clr-dg-cell (click)="updateTaskLocationStatus(task)">
                        <ng-container *ngIf="task.locationStatus == 'INHOUSE'; else elseTemplate">
                            <clr-icon shape="house" size="24"></clr-icon>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <ng-container *ngIf="task.locationStatus == 'NOTINHOUSE'; else elseTemplate">
                                <clr-icon shape="car" size="24" class="is-solid"></clr-icon>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <clr-icon shape="crown" size="24" class="is-solid"></clr-icon>
                            </ng-template>
                        </ng-template>
                    </clr-dg-cell>
                    <clr-dg-cell>{{task.uid}}</clr-dg-cell>
                    <clr-dg-cell>{{task.description}}</clr-dg-cell>
                    <clr-dg-cell>{{task.dentist.name}}</clr-dg-cell>
                    <clr-dg-cell>{{task.patient}}</clr-dg-cell>
                    <clr-dg-cell>{{getTechnicianNameById(task.taskOwnerId)}}</clr-dg-cell>
                    <clr-dg-cell>{{task.createdAt | date:'medium'}}</clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgTotalItems]="tasks.length" [(clrDgPage)]="pageIndex"
                        [clrDgPageSize]="pageSize">
                        <clr-dg-page-size [clrPageSizeOptions]="[20,50,100,150]">Arbeiten pro Seite</clr-dg-page-size>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                        von {{pagination.totalItems}} Arbeiten
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>
        <button clrTabLink>Auftragssuche</button>
        <clr-tab-content>
            <h2>Auftragssuche</h2>
            <div>

                <clr-input-container>
                    <label>Patienten-Uid</label>
                    <input (ngModelChange)="searchTask($event)" clrInput placeholder="123" name="search"
                        [(ngModel)]="search" />
                </clr-input-container>
            </div>
            <clr-datagrid>
                <clr-dg-column>Info</clr-dg-column>
                <clr-dg-column>Status</clr-dg-column>
                <clr-dg-column>Standort</clr-dg-column>
                <clr-dg-column>UID</clr-dg-column>
                <clr-dg-column>Beschreibung</clr-dg-column>
                <clr-dg-column>Zahnarzt</clr-dg-column>
                <clr-dg-column>Patient</clr-dg-column>
                <clr-dg-column>Techniker ID</clr-dg-column>
                <clr-dg-column>Erstellungsdatum</clr-dg-column>

                <clr-dg-row *clrDgItems="let task of searchResults">

                    <clr-dg-cell>
                        <clr-icon style="margin: 5px;" (click)="detailTask(task.id)" shape="info-standard"></clr-icon>
                        <clr-icon style="margin: 5px;" (click)="openPatchModal(task)" shape="edit"></clr-icon>
                        <clr-icon style="margin: 5px;" (click)="openDeActivateModal(task)" shape="close"></clr-icon>
                        <clr-icon style="margin: 5px;" (click)="openBatchScanModal(task)" shape="clipboard"></clr-icon>
                    </clr-dg-cell>
                    <clr-dg-cell>
                        <ng-container *ngIf="task.status == 'ACTIVE'; else elseTemplate2">
                            <clr-icon (click)="deActivateSingleTask(task)" shape="check-circle" size="24"></clr-icon>
                        </ng-container>
                        <ng-template #elseTemplate2>
                            <clr-icon (click)="activateTask(task)" shape="error-standard" size="24" class="is-solid"
                                style="color: red;"></clr-icon>
                        </ng-template>
                    </clr-dg-cell>
                    <clr-dg-cell (click)="updateTaskLocationStatus(task)">
                        <ng-container *ngIf="task.locationStatus == 'INHOUSE'; else elseTemplate">
                            <clr-icon shape="house" size="24"></clr-icon>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <clr-icon shape="car" size="24" class="is-solid"></clr-icon>
                        </ng-template>
                    </clr-dg-cell>
                    <clr-dg-cell><div [innerHTML]="transformString(task.uid)"></div> </clr-dg-cell>
                    <clr-dg-cell>{{task.description}}</clr-dg-cell>
                    <clr-dg-cell>{{task.dentist.name}}</clr-dg-cell>
                    <clr-dg-cell>{{task.patient}}</clr-dg-cell>
                    <clr-dg-cell>{{getTechnicianNameById(task.taskOwnerId)}}</clr-dg-cell>
                    <clr-dg-cell>{{task.createdAt | date:'medium'}}</clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgTotalItems]="tasks.length" [(clrDgPage)]="pageIndex"
                        [clrDgPageSize]="pageSize">
                        <clr-dg-page-size [clrPageSizeOptions]="[20,50,100,150]">Arbeiten pro Seite</clr-dg-page-size>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                        von {{pagination.totalItems}} Arbeiten
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </clr-tab-content>
    </clr-tab>


</clr-tabs>


<div *ngIf="taskDetail">
    <clr-modal [clrModalSize]="'md'" [(clrModalOpen)]="deActivateModal">
        <h3 class="modal-title">Auftrag - {{ taskDetail.description }} - Abschließen</h3>
        <div class="modal-body">
            <p>Soll der Auftrag - <strong>{{ taskDetail.description }}</strong> - von Patient - <strong>{{
                    taskDetail.patient }}</strong> - abgeschlossen werden?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline" (click)="deActivateModal = false">Zurück</button>
            <button type="button" class="btn btn-danger" (click)="deActivateTask()">Abschließen</button>
        </div>
    </clr-modal>
</div>


<div *ngIf="taskDetail && detailModal">
    <clr-modal [(clrModalOpen)]="detailModal">
        <div class="modal-body">
            <h2 class="modal-title">{{ taskDetail.description }}</h2>
            <app-task-view-detail [technicians]="this.technicians" [taskDetail]="this.taskDetail">
            </app-task-view-detail>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="detailModal = false">Ok</button>
            </div>
        </div>
    </clr-modal>
</div>



<div *ngIf="taskDetail">
    <clr-modal [(clrModalOpen)]="patchModal" [clrModalClosable]="false">
        <h3 class="modal-title">{{ taskDetail.description }} bearbeiten</h3>
        <div class="modal-body">
            <clr-input-container>
                <label>Datext UID</label>
                <input clrInput name="name" value="{{ taskDetail.uid }}" [(ngModel)]="taskDetail.uid" />
            </clr-input-container>
            <clr-input-container>
                <label>Patient</label>
                <input clrInput name="name" value="{{ taskDetail.patient }}" [(ngModel)]="taskDetail.patient" />
            </clr-input-container>
            <clr-input-container>
                <label>Beschreibung</label>
                <input clrInput name="name" value="{{ taskDetail.description }}" [(ngModel)]="taskDetail.description" />
            </clr-input-container>
            <clr-select-container>
                <label>Techniker</label>
                <select clrSelect name="options" value="{{ taskDetail.taskOwnerId }}"
                    [(ngModel)]="taskDetail.taskOwnerId">
                    <option *ngFor="let item of technicians" value="{{item.id}}">{{item.firstName}}
                        {{item.lastName}}</option>
                </select>
            </clr-select-container>
            <clr-select-container>
                <label>Zahnarzt</label>
                <select clrSelect name="options" value="{{ taskDetail.dentistId }}" [(ngModel)]="taskDetail.dentistId">
                    <option *ngFor="let item of dentists" value="{{item.id}}">{{item.name}}</option>
                </select>
            </clr-select-container>

        </div>
        <div class="modal-footer">
            <button (click)="patchModal = !patchModal" class="btn btn-outline" type="button">Zurück</button>
            <button (click)="updateTask()" class="btn btn-primary" type="button">Speichern</button>
        </div>
    </clr-modal>
</div>

<div *ngIf="batchScanModal">
    <clr-modal [(clrModalOpen)]="batchScanModal" [clrModalClosable]="false" [clrModalSize]="xl">
        <h3 class="modal-title">{{ taskDetail.patient }} - Chargen</h3>
        <div class="modal-body">
            <table class="table">
                <caption>
                  Verwendete Chargen
                </caption>
                <thead>
                  <tr >
                    <th>Produkt</th>
                    <th>Charge</th>
                    <th>Verwendet am</th>
                    <th>Haltbarkeit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of batchScans">
                    <td>{{item.batch.product.productName}}</td>
                    <td>{{item.batch.lot}}</td>
                    <td>{{item.scanOn | date:"medium"}}</td>
                    <td>{{item.batch.bestBefore | date:"medium"}}</td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div class="modal-footer">
            <button (click)="batchScanModal = !batchScanModal" class="btn btn-outline" type="button">Zurück</button>
        </div>
    </clr-modal>
</div>
