import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BatchProduct, ProductType } from '../models/batch-product.interface';
import { BatchScan } from '../models/batch-scan.interface';
import { Batch } from '../models/batch.interface';
import { DataService } from '../services/data.service';
import { parse, addYears } from 'date-fns';
import * as short from 'short-uuid';
import { HTMLOptions, jsPDF } from 'jspdf';
import 'svg2pdf.js';
import { TaskBatchSearch } from '../models/task-batch-search.interface';
import { RestService } from '../services/rest.service';
import { ExcelService } from '../services/excel.service';
import { HelperService } from '../services/helper.service'

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss'],
})
export class ChargeComponent implements OnInit {
  batches: Batch[] = [];
  productBatches: Batch[] = [];
  batchProducts: BatchProduct[] = [];
  batchScans: BatchScan[] = [];

  detailBatch: Batch;
  selectedBatch: Batch;
  detailBatchProduct: BatchProduct;
  detailBatchScan: BatchScan;

  detailBatchModal = false;
  detailBatchProductModal = false;
  detailBatchScanModal = false;

  addBatchModal = false;
  addBatchProductModal = false;
  addBatchScanModal = false;

  batchProductSelection: BatchProduct;

  search: string;
  searching = false;
  searchButtonDisabled = true;
  searchResults: TaskBatchSearch[] = [];
  selected: Batch[] = [];
  selectedBatchProduct?: BatchProduct;
  singleSelectedProduct: BatchProduct = null;

  constructor(
    private data: DataService,
    private rest: RestService,
    private excelService: ExcelService
  ) {}

  ngOnInit(): void {
    this.data.refreshBatch();
    this.data.currentBatches.subscribe((b) => (this.batches = b));
    this.data.refreshBatchProduct();
    this.data.currentBatchProducts.subscribe((p) => (this.batchProducts = p));
    // this.data.refreshBatchScan();
    // this.data.currentBatchScans.subscribe(s => this.batchScans = s);
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(
      this.searchResults,
      'Chargen-Suchergebnisse' + new Date().toLocaleDateString()
    );
  }

  async getBatchesByProductId(id: number): Promise<void> {
    this.selectedBatchProduct = this.batchProducts.find((v) => v.id === id);
    this.productBatches = await this.rest.getBatchesByProductId(id);
  }

  compareDates(date: Date): boolean {
    return new Date(date).getTime() < new Date().getTime() - 86400000;
  }

  searchBatch(): void {
    if (this.search) {
      if (this.search.length > 2) {
        this.searchButtonDisabled = false;
        this.getBatchSearchData().then(r => console.log(r));
      } else {
        this.searchResults = [];
        this.searchButtonDisabled = true;
      }
    } else {
      this.searchResults = [];
    }
  }

  async getBatchSearchData(): Promise<void> {
    this.searching = true;
    this.searchResults = await this.rest.getTaskBatchSearch(this.search);
    this.searching = false;
  }

  searchTermMarker(term: string): string[] {
    const str = term.split(this.search);
    str.splice(1, 0, this.search);
    return str;
  }

  async createBatch(bestBefore: string, lot: string): Promise<void> {
    const productId = this.selectedBatchProduct.id;
    let date: Date | null;
    const uid = short('abcdf1234567890').generate();
    if (bestBefore === '') {
      date = null;
    } else {
      date = parse(bestBefore, 'dd.MM.yyyy', new Date());
    }

    const test = await this.data.createBatch(uid, lot, date, productId);
    console.log(test);
    this.addBatchModal = false;
    await this.getBatchesByProductId(this.selectedBatchProduct.id);
  }

  async createBatchScan(taskId: number, batchId: number): Promise<void> {
    await this.data.createBatchScan(taskId, batchId);
    this.addBatchScanModal = false;
  }

  async createBatchProduct(productName: string): Promise<void> {
    const productType = ProductType.SELF_GENERATED;
    await this.data.createBatchProduct(productName, productType);
    this.addBatchProductModal = false;
  }

  async updateBatch(): Promise<void> {
/*    this.detailBatch.bestBefore = parse(
      this.detailBatch.bestBefore.toString(),
      'dd.MM.yyyy',
      new Date()
    );*/
    await this.data.updateBatch(this.detailBatch);
    this.detailBatch = null;
    this.detailBatchModal = false;
  }

  async updateBatchScan(): Promise<void> {
    await this.data.updateBatchScan(this.detailBatchScan);
    this.detailBatchScan = null;
    this.detailBatchScanModal = false;
  }

  async updateBatchProduct(): Promise<void> {
    await this.data.updateBatchProduct(this.detailBatchProduct);
    this.detailBatchProduct = null;
    this.detailBatchProductModal = false;
  }

  openBatchPatchModal(data: Batch): void {
    this.detailBatch = {
      id: data.id,
      uid: data.uid,
      lot: data.lot,
      bestBefore: new Date(data.bestBefore.toString()),
      createdAt: new Date(data.createdAt.toString()),
      product: data.product,
      productId: data.productId,
    };
    this.detailBatchModal = true;
  }

  openBatchProductPatchModal(data: BatchProduct): void {
    this.detailBatchProduct = data;
    this.detailBatchProductModal = true;
  }

  openBatchScanPatchModal(data: BatchScan): void {
    this.detailBatchScan = data;
    this.detailBatchScanModal = true;
  }

  async generatePdfDoc(batch: Batch): Promise<jsPDF> {
    const { id, lot, product } = batch;
    const doc = new jsPDF('l', 'mm', [50, 12]);

    const element: any = document.getElementById(`barcode-${id}`).firstChild
      .firstChild;
    await doc.svg(element, { x: 1, y: 1, height: 10, width: 10 });
    doc.setFont('helvetica');
    doc.setLineWidth(3);
    doc.setFontSize(8);
    doc.text(`Produkt: ${product.productName}`, 11, 5);
    doc.text(`LOT: ${lot}`, 11, 9);
    return doc;
  }

  testMultiPrint(): void {
    this.downloadAsMultiPDF(this.selected).then(r => console.log(r));
  }

  async downloadAsMultiPDF(batch: Batch[]): Promise<void> {
    const doc = new jsPDF('l', 'mm', [50, 12]);

    for (const item of batch) {
      const { id, lot, product } = item;
      const element: any = document.getElementById(`barcode-${id}`).firstChild
        .firstChild;
      await doc.svg(element, { x: 1, y: 1, height: 10, width: 10 });
      doc.setFont('helvetica');
      doc.setLineWidth(3);
      doc.setFontSize(8);
      doc.text(`Produkt: ${product.productName}`, 11, 5);
      doc.text(`LOT: ${lot}`, 11, 9);
      doc.addPage();
    }
    //  doc.html(canvas.innerHTML, opt);
    // doc.save(`${product}-${lot}.pdf`);
    doc.autoPrint(); // <<--------------------- !!
    doc.output('dataurlnewwindow');
  }

  async downloadAsPDF(batch: Batch): Promise<void> {
    const doc = await this.generatePdfDoc(batch);

    //  doc.html(canvas.innerHTML, opt);
    // doc.save(`${product}-${lot}.pdf`);
    doc.autoPrint(); // <<--------------------- !!
    doc.output('dataurlnewwindow');
  }
}
