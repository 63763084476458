import { Component, OnInit } from '@angular/core';
import { Lab } from 'src/app/models/lab.interface';
import { Technician } from 'src/app/models/technician.interface';
import { DataService } from 'src/app/services/data.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-lab-content',
  templateUrl: './lab-content.component.html',
  styleUrls: ['./lab-content.component.scss']
})
export class LabContentComponent implements OnInit {

  technicians: Technician[] = [];
  labs: Lab[] = [];

  addModal: boolean;
  patchModal: boolean;
  detailData: Lab;

  constructor(
    private data: DataService,
  ) { }

  ngOnInit(): void {
    this.data.refreshTechnicians();
    this.data.currentTechnicians.subscribe(v => this.technicians = v);

    this.data.refreshLabs();
    this.data.currentLabs.subscribe(v => this.labs = v);
  }

  async updateLab(): Promise<void> {
    await this.data.updateLab(this.detailData);
    this.detailData = null;
  }

  openPatchModal(lab: Lab): void {
    this.detailData = lab;
    this.patchModal = true;
  }

  async createLab(labName: string): Promise<void> {
    await this.data.createLab(labName);
    this.addModal = false;
  }
}
