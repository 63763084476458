import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginRes } from 'src/app/models/loginResponce.interface';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  error = false;
  password = '';
  email = '';

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  async signIn(): Promise<void> {
    const data: LoginRes = await this.authService.signIn(this.email, this.password);
    if (data.accessToken) {
      this.error = false;

      this.authService.setSession(data.accessToken);
      await this.router.navigate(['/app/home']);
    } else {

    }

  }

}
