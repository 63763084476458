import { Injectable } from '@angular/core';
import { RRuleFreq, RRuleOptions } from '../models/rRule-interface';

@Injectable({
  providedIn: 'root',
})
export class HelperService {


  descriptionSplitter(description: string): string {
    const splitList = description.replace(',', ' ').split('/ ');
    const newString = this.stringBuilder(
      splitList[0],
      splitList[1],
      splitList[2]
    );
    return newString.replace(' ', '');
  }

  patientFormatter(patient: string): string {
    const splitString = patient.split(' ');
    const lastIdx = splitString.length - 1;
    if (splitString.length > 1) {
      if (splitString.length === 2) {
        return `${splitString[0]},${splitString[1][0]}.`;
      } else {
        return `${splitString[0]},${splitString[1]}${splitString[2][0]}.`;
      }
    } else {
      return patient;
    }
  }

  dentistFormatter(dentist: string): string {
    const splitString = dentist.split(' ');
    const lastIdx = splitString.length - 1;
    if (splitString[lastIdx].length > 5) {
      return splitString[lastIdx].substring(0, 4) + '.';
    } else {
      return splitString[lastIdx];
    }
  }

  shorter(data: string): string {
    return (data.length > 4) ? data.substring(0, 4) + '.' : data + '.';
  }

  descriptionFormatter(description: string): string {
    const splitString = description.split(' ');
    const mappedList = splitString.map((v) => this.shorter(v));
    return mappedList.join();
  }

  stringBuilder(patient: string, dentist: string, description: string): string {
    return `${this.patientFormatter(patient)}/${this.dentistFormatter(
      dentist
    )}/${this.descriptionFormatter(description)}`;
  }

  rRuleStringBuilder(rRuleOptions: RRuleOptions): string {
    const { freq, count, interval } = rRuleOptions;
    return !freq || !count || !interval || freq === RRuleFreq.EMPTY
      ? null
      : `RRULE:FREQ=${freq};COUNT=${count};INTERVAL=${interval}`;
  }

  rRuleOptionsBuilder(rRuleString: string): RRuleOptions {
    const splitString = rRuleString.split(';');
    const splitMap: Map<string, string> = new Map();
    splitString.forEach((v) => splitMap.set(v.split('=')[0], v.split('=')[1]));
    return {
      freq: RRuleFreq[splitMap.get('RRULE:FREQ')],
      count: +splitMap.get('COUNT'),
      interval: +splitMap.get('INTERVAL'),
    };
  }

  rRuleFreqEnumToText(freqEnum: RRuleFreq): string {
    switch (freqEnum) {
      case RRuleFreq.DAILY:
        return 'täglich';
      case RRuleFreq.WEEKLY:
        return 'wöchentlich';
      case RRuleFreq.MONTHLY:
        return 'monatlich';
      case RRuleFreq.YEARLY:
        return 'jährlich';
      default:
        return 'unbekannt';
    }
  }

  rRuleFreqEnumToTextTimeSingular(freqEnum: RRuleFreq): string {
    switch (freqEnum) {
      case RRuleFreq.DAILY:
        return 'jeden Tag';
      case RRuleFreq.WEEKLY:
        return 'jede Woche';
      case RRuleFreq.MONTHLY:
        return 'jeden Monat';
      case RRuleFreq.YEARLY:
        return 'jedes Jahr';
      default:
        return 'unbekannt';
    }
  }

  rRuleFreqEnumToTextTimePlural(freqEnum: RRuleFreq): string {
    switch (freqEnum) {
      case RRuleFreq.DAILY:
        return 'Tage';
      case RRuleFreq.WEEKLY:
        return 'Wochen';
      case RRuleFreq.MONTHLY:
        return 'Monate';
      case RRuleFreq.YEARLY:
        return 'Jahre';
      default:
        return 'unbekannt';
    }
  }

  rRuleOptionsToText(rRuleOptions: RRuleOptions): string {
    const { freq, count, interval } = rRuleOptions;
    if (!freq || !count || !interval || freq === RRuleFreq.EMPTY) {
      return 'keine Regel vorhanden!';
    }
    if (interval === 1) {
      return `Der Termin wird ${this.rRuleFreqEnumToTextTimeSingular(
        freq
      )} für ingesamt ${count} mal wiederholt!`;
    } else {
      return `Der Termin wird alle ${interval} ${this.rRuleFreqEnumToTextTimePlural(
        freq
      )} für ingesamt ${count} mal wiederholt!`;
    }
  }
}
