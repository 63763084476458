import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {DataService} from '../../services/data.service';
import {Router} from '@angular/router';
import {LoginRes} from '../../models/loginResponce.interface';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  firstName = '';
  lastName = '';
  company = '';
  error = false;
  password = '';
  password2 = '';
  email = '';

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  get checkFirstName(): boolean {
    return this.firstName.length > 1;
  }

  get checkLastName(): boolean {
    return this.lastName.length > 1;
  }

  get checkCompany(): boolean {
    return this.company.length > 1;
  }

  get checkPassword(): boolean {
    const regEx: RegExp = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return regEx.test(this.password);
  }

  get checkEmail(): boolean {
    const regEx: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regEx.test(this.email);
  }

  get checkSecondPassword(): boolean {
    return this.password === this.password2 &&  this.password.length > 5;
  }

  get checkIfCanSignUp(): boolean {
    return this.firstName.length > 2 &&
      this.lastName.length > 2 &&
      this.company.length > 2 &&
      this.checkPassword &&
      this.checkEmail &&
      this.checkSecondPassword;
  }

  async signUp(): Promise<void> {
    await this.authService.signUp(this.firstName, this.lastName, this.company, this.email, this.password);
    await this.signIn();
  }

  async signIn(): Promise<void> {
    const data: LoginRes = await this.authService.signIn(this.email, this.password);
    if (data.accessToken) {
      this.error = false;

      this.authService.setSession(data.accessToken);
      await this.router.navigate(['/app/home']);
    }

  }

}
