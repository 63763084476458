import { Batch } from './batch.interface';

export interface BatchProduct {
    id: number;
    productName: string;
    productType: ProductType;
    batches: Batch[];
}


export enum ProductType {
    HIBC = 'HIBC',
    SELF_GENERATED = 'SELF_GENERATED',
}

