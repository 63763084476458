<div class="clr-row clr-justify-content-between">
    <div class="clr-col-6 clr-align-self-center">
        <h3 class="pa-5">
            Zahnärtze
        </h3>
        <button (click)="addModal = !addModal" class="btn btn-sm">Neuen Zahnarzt
            hinzufügen</button>
        <clr-datagrid>
            <clr-dg-column>Name</clr-dg-column>
            <clr-dg-column>Standort</clr-dg-column>

            <clr-dg-row (click)="openPatchModal(dent)" *ngFor="let dent of dentists">
                <clr-dg-cell>{{dent.name}}</clr-dg-cell>
                <clr-dg-cell>{{dent.location}}</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>{{dentists.length}} Zahnärzte</clr-dg-footer>
        </clr-datagrid>
    </div>

    <clr-modal [(clrModalOpen)]="addModal" [clrModalClosable]="false">
        <h3 class="modal-title">Neuen Zahnarzt hinzufügen</h3>
        <div class="modal-body">
            <clr-input-container>
                <label>Name</label>
                <input clrInput name="name" #name />
            </clr-input-container>
            <clr-input-container>
                <label>Standort</label>
                <input clrInput name="name" #location />
            </clr-input-container>
        </div>
        <div class="modal-footer">
            <button (click)="addModal = !addModal" class="btn btn-outline" type="button">Cancel</button>
            <button
                (click)="createDentist(name.value, location.value)"
                class="btn btn-primary" type="button">Ok</button>
        </div>
    </clr-modal>

    <div *ngIf="detailData">
        <clr-modal [(clrModalOpen)]="patchModal" [clrModalClosable]="false">
            <h3 class="modal-title">{{ detailData.name }} bearbeiten</h3>
            <div class="modal-body">
                <clr-input-container>
                    <label>Zahnarzt</label>
                    <input clrInput name="name" value="{{ detailData.name }}"
                        [(ngModel)]="detailData.name" />
                </clr-input-container>
                <clr-input-container>
                    <label>Standort</label>
                    <input clrInput name="name" value="{{ detailData.location }}" [(ngModel)]="detailData.location" />
                </clr-input-container>
            </div>
            <div class="modal-footer">
                <button (click)="patchModal = !patchModal" class="btn btn-outline" type="button">Cancel</button>
                <button (click)="updateDentist()" class="btn btn-primary" type="button">Ok</button>
            </div>
        </clr-modal>
    </div>