import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChargeComponent } from './charge/charge.component';
import { CalenderContentComponent } from './content/calender-content/calender-content.component';
import { CalenderStatusContentComponent } from './content/calender-status-content/calender-status-content.component';
import { DentistContentComponent } from './content/dentist-content/dentist-content.component';
import { LabContentComponent } from './content/lab-content/lab-content.component';
import { TaskContentComponent } from './content/task-content/task-content.component';
import { TaskManagementComponent } from './content/task-managment/task-managment.component';
import { TechnicianContentComponent } from './content/technician-content/technician-content.component';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { UserSettingsComponent } from './settings/user-settings/user-settings.component';

const routes: Routes = [
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },

  {
    path: 'app', component: DashboardComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'labs', component: LabContentComponent },
      { path: 'calender', component: CalenderContentComponent },
      { path: 'dentist', component: DentistContentComponent },
      { path: 'technicians', component: TechnicianContentComponent },
      { path: 'tasks', component: TaskContentComponent },
      { path: 'settings', component: UserSettingsComponent },
      { path: 'calenderstatus', component: CalenderStatusContentComponent },
      { path: 'addTask', component: TaskManagementComponent },
      { path: 'charge', component: ChargeComponent }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
