<app-home></app-home>
<clr-wizard #wizard [(clrWizardOpen)]="open" [clrWizardSize]="'lg'" (clrWizardOnFinish)="doFinish()"
    (clrWizardOnCancel)="doReset()">

    <clr-wizard-title>
        Neuer Auftrag
    </clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Abbrechen</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">zurück</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Weiter</clr-wizard-button>
    <clr-wizard-button *ngIf="checkButton()" [type]="'finish'">Speichern</clr-wizard-button>

    <clr-wizard-page>
        <ng-template clrPageTitle>Patient und Beschreibung</ng-template> <!-- mandatory -->
        <p>Vergebe eine individuelle ID:</p>

        <clr-input-container>
            <label>Auftrags-ID</label>
            <input clrInput type="number" name="description" [(ngModel)]="model.uid" required>>
        </clr-input-container>
        <p>Wie heisst der Patient? Bitte Vor- und Nachnamen in eine Zeile2</p>
        <clr-input-container>
            <label>Patient</label>
            <input clrInput  #stepTwoInput [(ngModel)]="model.patient" />
        </clr-input-container>
        <p>Beschreibe die Arbeit z.B "Zirkonbrücke von 13-24 vollverblendet A2"</p>
        <clr-textarea-container>
            <label>Beschreibung</label>
            <textarea clrTextarea name="description" [(ngModel)]="model.description" required></textarea>
        </clr-textarea-container>


    </clr-wizard-page>

    <clr-wizard-page>
        <ng-template clrPageTitle>Techniker und Zahnarzt</ng-template> <!-- mandatory -->
        <p>Welcher Techniker startet mit der Arbeit?</p>
        <clr-combobox-container>
            <label>Techniker</label>
            <clr-combobox [(ngModel)]="selectedTechnician" name="myState" required>
                <clr-options>
                    <clr-option
                        *clrOptionItems="let item of technicians; field:'lastName'"
                        [clrValue]="item">
                        <clr-icon shape="user" role="img" aria-label="user"></clr-icon> - {{item.technicianId}} -
                        {{item.firstName}} {{item.lastName}}
                    </clr-option>
                </clr-options>
            </clr-combobox>
        </clr-combobox-container>
        <p>Von welchem Zahnarzt ist die Arbeit?</p>
        <clr-combobox-container>
            <label>Zahnarzt</label>
            <clr-combobox [(ngModel)]="selectedDentist" name="myState" required>
                <clr-options>
                    <clr-option *clrOptionItems="let item of dentists; field:'name'" [clrValue]="item">
                        <clr-icon shape="user" role="img" aria-label="user"></clr-icon>
                        {{item.name}}
                    </clr-option>
                </clr-options>
            </clr-combobox>
        </clr-combobox-container>

    </clr-wizard-page>


    <clr-wizard-page>
        <ng-template clrPageTitle>Termine</ng-template>
        <clr-checkbox-wrapper>
            <input type="checkbox" clrCheckbox name="options" required value="option1" [(ngModel)]="allowEmpty" />
            <label>Keine Termine erlauben!</label>
          </clr-checkbox-wrapper>
        <select clrSelect name="options" [(ngModel)]="selectedCalenderStatus">
            <option *ngFor="let item of calenderStatus" value="{{item.id}}">{{item.statusDescription}}</option>
        </select>
        <clr-date-container>
            <input type="date" clrDate name="demo" [(ngModel)]="selectedDate" min="2020-11-17">
        </clr-date-container>
        <h3>{{this.getTimeString()}}</h3>
        <strong>Stunden</strong>
        <div>
            <a (click)="hours = item" *ngFor="let item of this.dateService.hourArray"
                [ngClass]="item == this.hours? 'label-warning' : 'label-purple'" class="label  clickable">{{ item }}</a>
        </div>
        <strong>Minuten</strong>
        <div>
            <a (click)="minutes = item" *ngFor="let item of this.dateService.minutesArray"
                [ngClass]="item == this.minutes? 'label-warning' : 'label-purple'"
                class="label  clickable">{{ item }}</a>
        </div>
        <button (click)="addDateToList()" class="btn btn-sm">Termin hinzufügen</button>
        <h6>Termine:</h6>
        <table class="table table-noborder">
            <thead>
                <tr>
                    <th>Farbe</th>
                    <th class="left">Termin</th>
                    <th class="left">Datum</th>
                    <th class="left">Entfernen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dates">
                    <td>
                        <div [ngStyle]="{'background': item.calenderStatus.color}"
                            style="border-radius: 7px; width: 14px; height: 14px; margin-top: 5px; margin-left: 15px;">
                        </div>
                    </td>
                    <td class="left"> {{ item.calenderStatus.statusDescription}}</td>
                    <td class="left"><div class="clr-col" >Final: {{item.date | date:'medium'}}<strong></strong><br>Intern: {{getInterDateString(item.date)}}</div></td>
                    <td class="left" (click)="removeFromDateList(item.id)">X</td>
                </tr>
            </tbody>
        </table>
    </clr-wizard-page>
</clr-wizard>
