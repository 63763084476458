import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Lab} from '../models/lab.interface';
import {Technician} from '../models/technician.interface';
import {User} from '../models/user.interface';
import {AuthService} from './auth.service';
import {Dentist} from '../models/dentist.interface';
import {CalenderStatus} from '../models/calender-status.interface';
import {TaskDate} from '../models/taskDate.interface';
import {Task} from '../models/task.interface';
import {TaskStatus} from '../models/task-status.enum';
import {environment} from 'src/environments/environment';
import {CalenderDateRange} from '../models/calenderDate.interface';
import {BatchProduct, ProductType} from '../models/batch-product.interface';
import {Batch} from '../models/batch.interface';
import {BatchScan} from '../models/batch-scan.interface';
import {addMonths, parse} from 'date-fns';
import {TaskBatchSearch} from '../models/task-batch-search.interface';
import {GeneralDate} from '../models/general-date.interface';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  private REST_API_SERVER = environment.APIEndpoint;

  private token: string;

  constructor(private http: HttpClient, private authService: AuthService) {}

  checkToken(): void {
    if (!this.token) {
      this.token = this.authService.getLocalStorageToken();
    }
  }

  getTaskBatchSearch(search: string): Promise<TaskBatchSearch[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return this.http
      .get<TaskBatchSearch[]>(
        this.REST_API_SERVER + `/batch/search?batch=${search}`,
        { headers }
      )
      .toPromise();
  }

/*  getTaskSearch(search: string): Promise<Task[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return this.http
      .get<Task[]>(this.REST_API_SERVER + `/tasks/search?patient=${search}`, {
        headers,
      })
      .toPromise();
  }*/

  getTaskSearch(search: string): Promise<Task[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return this.http
      .get<Task[]>(this.REST_API_SERVER + `/tasks/search-uid?patient=${search}`, {
        headers,
      })
      .toPromise();
  }

  getUserData(): Promise<User> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return this.http
      .get<User>(this.REST_API_SERVER + '/auth', { headers })
      .toPromise();
  }

  getDentists(): Observable<Dentist[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return this.http.get<Dentist[]>(this.REST_API_SERVER + '/dentists', {
      headers,
    });
  }

  createDentist(name: string, location: string): void {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      name,
      location,
      level: 1.0,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    this.http
      .post<Dentist>(this.REST_API_SERVER + '/dentists', body, { headers })
      .subscribe((data) => console.log(data));
  }

  updateDentist(dentist: Dentist): void {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(dentist);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    this.http
      .patch<Dentist>(this.REST_API_SERVER + '/dentists', body, { headers })
      .subscribe((data) => console.log(data));
  }

  async getCalenderStatus(): Promise<CalenderStatus[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return await this.http
      .get<CalenderStatus[]>(this.REST_API_SERVER + '/calenderstatus', {
        headers,
      })
      .toPromise();
  }

  async createCalenderStatus(
    statusDescription: string,
    color: string,
    fontColor: string
  ): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      statusDescription,
      color,
      fontColor,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<CalenderStatus>(this.REST_API_SERVER + '/calenderstatus', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  async updateCalenderStatus(calenderStatus: CalenderStatus): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(calenderStatus);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<CalenderStatus>(this.REST_API_SERVER + '/calenderstatus', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  /*   async getTaskDates(): Promise<TaskDate[]> {
    console.log(await this.getTaskDatesBetween());
    if (!this.token) {
      this.token = this.authService.getLocalStorageToken();
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<TaskDate[]>(this.REST_API_SERVER + '/dates/raw', { headers })
      .toPromise();
    console.log(data);
    return data;
  } */

  /*   async getTaskDates(
    from: Date = addWeeks(new Date(), -1),
    to: Date = addWeeks(new Date(), 1)
  ): Promise<TaskDate[]> {
    if (!this.token) {
      this.token = this.authService.getLocalStorageToken();
    }
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      from: addWeeks(from, -2),
      to: addWeeks(from, 2),
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .post<TaskDate[]>(this.REST_API_SERVER + '/dates/between/raw', body, {
        headers,
      })
      .toPromise();
    return data;
  } */

  async getGeneralDates(): Promise<GeneralDate[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      from: addMonths(new Date(), -2),
      to: addMonths(new Date(), 12),
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .post<GeneralDate[]>(this.REST_API_SERVER + '/general-date/raw', body, {
        headers,
      })
      .toPromise();
    return data;
  }

  async createGeneralDate(
    date: Date,
    description: string,
    calenderStatusId: number
  ): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      date,
      description,
      calenderStatusId,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<GeneralDate>(this.REST_API_SERVER + '/general-date', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  async updateGeneralDate(taskDate: GeneralDate): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(taskDate);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<GeneralDate>(this.REST_API_SERVER + '/general-date/', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  async deleteGeneralDate(generalDate: GeneralDate): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .delete<GeneralDate>(
        this.REST_API_SERVER + `/general-date/${generalDate.id}`,
        {
          headers,
        }
      )
      .toPromise();
    return true;
  }

  async getTaskDates(start: Date, end: Date): Promise<TaskDate[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      from: start, //  addMonths(new Date(), -2),
      to: end, // addMonths(new Date(), 12),
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return await this.http
      .post<TaskDate[]>(this.REST_API_SERVER + '/dates/between/raw', body, {
        headers,
      })
      .toPromise();
  }

  async createTaskDate(
    date: Date,
    taskId: number,
    calenderStatusId: number
  ): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      date,
      taskId,
      calenderStatusId,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<TaskDate>(this.REST_API_SERVER + '/dates', body, { headers })
      .toPromise();
    return true;
  }

  async updateTaskDate(taskDate: TaskDate): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(taskDate);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<TaskDate>(this.REST_API_SERVER + '/dates', body, { headers })
      .toPromise();
    return true;
  }

  async deleteTaskDate(id: number): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .delete<TaskDate>(this.REST_API_SERVER + `/dates/${id}`, { headers })
      .toPromise();
    return true;
  }

  async deleteCalenderStatus(id: number): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .delete<TaskDate>(this.REST_API_SERVER + `/calenderstatus/${id}`, {
        headers,
      })
      .toPromise();
    return true;
  }

  async getTasksByStatus(status: TaskStatus): Promise<Task[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return await this.http
      .get<Task[]>(this.REST_API_SERVER + '/tasks?status=' + status, {
        headers,
      })
      .toPromise();
  }

  async getTasksById(id: number): Promise<Task> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<Task>(this.REST_API_SERVER + '/tasks/' + id, { headers })
      .toPromise();
    const dates: TaskDate[] = [];
    for (const key in data.dates) {
      if (Object.prototype.hasOwnProperty.call(data.dates, key)) {
        const element = data.dates[key];
        dates.push(element);
      }
    }
    dates.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    data.dates = dates;
    return data;
  }

  async createTask(
    uid: string,
    description: string,
    patient: string,
    dentistId: number,
    taskOwnerId: number
  ): Promise<Task> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      uid,
      description,
      patient,
      taskOwnerId,
      dentistId,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return await this.http
      .post<Task>(this.REST_API_SERVER + '/tasks', body, {headers})
      .toPromise();
  }

  async updateTask(task: Task): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(task);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<Task>(this.REST_API_SERVER + '/tasks', body, { headers })
      .toPromise();
    return true;
  }

  async getDentists2(): Promise<Dentist[]> {
    if (!this.token) {
      this.token = this.authService.getLocalStorageToken();
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return await this.http
      .get<Dentist[]>(this.REST_API_SERVER + '/dentists', {headers})
      .toPromise();
  }

  async createDentist2(name: string, location: string): Promise<boolean> {
    if (!this.token) {
      this.token = this.authService.getLocalStorageToken();
    }
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      name,
      location,
      level: 1.0,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<Dentist>(this.REST_API_SERVER + '/dentists', body, { headers })
      .toPromise();
    return true;
  }

  async updateDentist2(dentist: Dentist): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(dentist);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<Dentist>(this.REST_API_SERVER + '/dentists', body, { headers })
      .toPromise();
    return true;
  }

  async getTechnicians2(): Promise<Technician[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<Technician[]>(this.REST_API_SERVER + '/technicians', { headers })
      .toPromise();
    return data;
  }

  async createTechnicians2(
    technicianId: string,
    firstName: string,
    profileImageUrl: string,
    lastName: string,
    labId: number
  ): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      technicianId,
      firstName,
      lastName,
      labId,
      profileImageUrl,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<Technician>(this.REST_API_SERVER + '/technicians', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  async updateTechnicians2(technician: Technician): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(technician);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<Technician>(this.REST_API_SERVER + '/technicians', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  async getLabs2(): Promise<Lab[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return await this.http
      .get<Lab[]>(this.REST_API_SERVER + '/labs', {headers})
      .toPromise();
  }

  async createLab2(labName: string): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      labName,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<Lab>(this.REST_API_SERVER + '/labs', body, { headers })
      .toPromise();
    return true;
  }

  async updateLab2(lab: Lab): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(lab);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<Lab>(this.REST_API_SERVER + '/labs', body, { headers })
      .toPromise();
    return true;
  }

  async getCalenderDates(): Promise<CalenderDateRange[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<CalenderDateRange[]>(this.REST_API_SERVER + '/calender', { headers })
      .toPromise();
    return data;
  }

  async getSingleCalenderDate(id: number): Promise<CalenderDateRange> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<CalenderDateRange>(this.REST_API_SERVER + `/calender/${id}`, {
        headers,
      })
      .toPromise();
    return data;
  }

  async createCalenderDate(
    from: string,
    to: string,
    color: string,
    notes: string,
    description: string,
    rRule: string
  ): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();

    const toDate = parse(to, 'd.MM.yyyy', new Date());
    const fromDate = parse(from, 'd.MM.yyyy', new Date());

    const body = JSON.stringify({
      from: new Date(
        fromDate.getFullYear(),
        fromDate.getMonth(),
        fromDate.getDate(),
        13,
        30
      ),
      to: new Date(
        toDate.getFullYear(),
        toDate.getMonth(),
        toDate.getDate(),
        13,
        30
      ),
      color,
      description,
      rRule,
      notes,
    });

    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<CalenderDateRange>(this.REST_API_SERVER + '/calender', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  async updateCalenderDate(
    calenderDateRange: CalenderDateRange
  ): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(calenderDateRange);
    console.log(body);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<CalenderDateRange>(this.REST_API_SERVER + '/calender', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  async deleteCalenderDate(id: number): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .delete<CalenderDateRange>(this.REST_API_SERVER + `/calender/${id}`, {
        headers,
      })
      .toPromise();
    return true;
  }

  async getBatchScans(): Promise<BatchScan[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<BatchScan[]>(this.REST_API_SERVER + '/batchscans', { headers })
      .toPromise();
    return data;
  }

  async getBatchScansByTaskId(id: number): Promise<BatchScan[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<BatchScan[]>(this.REST_API_SERVER + '/batchscans?task=' + id, {
        headers,
      })
      .toPromise();
    return data;
  }

  async createBatchScan(taskId: number, batchId: number): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      taskId,
      batchId,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<BatchScan>(this.REST_API_SERVER + '/batchscans', body, { headers })
      .toPromise();
    return true;
  }

  async updateBatchScan(batchScan: BatchScan): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(batchScan);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<BatchScan>(this.REST_API_SERVER + '/batchscans', body, { headers })
      .toPromise();
    return true;
  }

  async getBatches(): Promise<Batch[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<Batch[]>(this.REST_API_SERVER + '/batch', { headers })
      .toPromise();
    return data;
  }

  async getBatchesByProductId(id: number): Promise<Batch[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    const data = await this.http
      .get<Batch[]>(this.REST_API_SERVER + `/batch/product/${id}`, { headers })
      .toPromise();
    return data;
  }

  async createBatch(
    uid: string,
    lot: string,
    bestBefore: Date,
    productId: number
  ): Promise<Batch> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      uid,
      lot,
      bestBefore: bestBefore ? bestBefore : new Date('2100-01-01'),
      productId,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return await this.http
      .post<Batch>(this.REST_API_SERVER + '/batch', body, { headers })
      .toPromise();
  }

  async updateBatch(batch: Batch): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(batch);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<Batch>(this.REST_API_SERVER + '/batch', body, { headers })
      .toPromise();
    return true;
  }

  async getBatchProducts(): Promise<BatchProduct[]> {
    this.checkToken();
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    return await this.http
      .get<BatchProduct[]>(this.REST_API_SERVER + '/batchproducts', {headers})
      .toPromise();
  }

  async createBatchProduct(
    productName: string,
    productType: ProductType
  ): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify({
      productName,
      productType,
    });
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .post<BatchProduct>(this.REST_API_SERVER + '/batchproducts', body, {
        headers,
      })
      .toPromise();
    return true;
  }

  async updateBatchProduct(batchProduct: BatchProduct): Promise<boolean> {
    this.checkToken();
    let headers = new HttpHeaders();
    const body = JSON.stringify(batchProduct);
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + this.token);
    await this.http
      .patch<BatchProduct>(this.REST_API_SERVER + '/batchproducts', body, {
        headers,
      })
      .toPromise();
    return true;
  }
}
