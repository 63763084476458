<h1>Übersicht</h1>
<h3>Labore</h3>
<div class="clr-row">
    <div *ngFor="let item of labs" class="clr-col-sm-12 clr-col-md-4">
        <div class="card">
            <div class="card-header text-center">
                {{ item.labName }}
            </div>
            <div class="card-block">
                <div class="clr-row">
                    <div class="clr-col">
                        <div class="card-title text-center">
                            Aufträge
                        </div>
                        <div class="card-text text-center">
                            <h1>{{getTasksByLab(item.technicians)}}</h1>
                        </div>
                    </div>
                    <div class="clr-col">
                        <div class="card-title text-center">
                            Techniker
                        </div>
                        <div class="card-text text-center ">
                            <h1>{{getTechnicianLengthByLabId(item.id).toFixed(1)}}</h1>
                        </div>
                    </div>
                    <div class="clr-col">
                        <div class="card-title text-center">
                            Durchschnitt
                        </div>
                        <div class="card-text text-center">
                            <h1><strong>Ø {{(getTasksByLab(item.technicians) /
                                    getTechnicianLengthByLabId(item.id)).toFixed(1)}}</strong></h1>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<h3>Techniker</h3>
<div class="clr-row">
    <div *ngFor="let item of technicians" class="clr-col-sm-12 clr-col-md-6 clr-col-lg-4 clr-col-xl-2">
        <div (click)="openDetailModal(item.id)" class="card">
            <div class="card-header text-center">
                <img src="{{ item.profileImageUrl }}" alt="Bild" style="
                vertical-align: middle;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                "><br>
                {{ item.firstName }} {{ item.lastName }}
            </div>
            <div class="card-block">
                <div class="card-title text-center">
                    Aufträge
                </div>
                <div class="card-text text-center flex clr-flex-column">
                    <h1>{{getTasksNumberByTechnicianId(item.id)}}</h1>
                    <h4>{{getTasksLocationNumberByTechnicianId(item.id).inhouse}}<clr-icon shape="house" size="14">
                        </clr-icon> / {{getTasksLocationNumberByTechnicianId(item.id).notinhouse}}<clr-icon shape="car"
                            size="14"></clr-icon>
                    </h4>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="detailData">
    <clr-modal [clrModalSize]="'lg'" [(clrModalOpen)]="detailModal" [clrModalClosable]="false">
        <h3 class="modal-title">{{ detailData.firstName }} {{ detailData.lastName }} Details</h3>
        <div class="modal-body">
            <img src="{{ detailData.profileImageUrl }}" alt="Bild" style="
            vertical-align: middle;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            ">
            <h3>Aufträge:</h3>
            <clr-datagrid>
                <clr-dg-column>Status</clr-dg-column>
                <clr-dg-column>Patient</clr-dg-column>
                <clr-dg-column>Beschreibung</clr-dg-column>
                <clr-dg-column>Zahnarzt</clr-dg-column>
                <clr-dg-column>Datum</clr-dg-column>
                <clr-dg-row *ngFor="let task of getTasksByTechnicianId(detailData.id)">

                    <clr-dg-cell *ngIf="(task.locationStatus == 'INHOUSE')">
                        <clr-icon shape="home"></clr-icon>
                    </clr-dg-cell>
                    <clr-dg-cell *ngIf="(task.locationStatus != 'INHOUSE')">
                        <clr-icon class="is-solid" shape="car"></clr-icon>
                    </clr-dg-cell>
                    <clr-dg-cell>{{task.patient}}</clr-dg-cell>
                    <clr-dg-cell>{{task.description}}</clr-dg-cell>
                    <clr-dg-cell>{{task.dentist.name}}</clr-dg-cell>
                    <clr-dg-cell>{{task.createdAt | date:'medium'}}</clr-dg-cell>
                    <clr-dg-row-detail *clrIfExpanded>
                        <table class="table table-noborder">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Status-Beschreibung</th>
                                    <th>Datum</th>
                                    <th>Abgeschlossen</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let date of getTaskDatesByTaskID(task.id)">
                                    <td>
                                        <div [ngStyle]="{'background': date.calenderColor}"
                                            style="border-radius: 7px; width: 14px; height: 14px; margin-top: 5px; margin-left: 15px;">
                                        </div>
                                    </td>
                                    <td *ngIf="checkDate(date.date)"> <s>{{date.calenderStatusDescription}}</s>
                                    </td>
                                    <td *ngIf="checkDate(date.date)"> <s>{{date.date | date:'medium'}}</s> </td>
                                    <td *ngIf="!checkDate(date.date)">{{date.calenderStatusDescription}}</td>
                                    <td *ngIf="!checkDate(date.date)"><div class="clr-col" >Final: {{date.date | date:'medium'}}<strong></strong><br>Intern: {{getInterDateString(date.date)}}</div></td>
                                    <td *ngIf="checkDate(date.date)" class="center">
                                        <clr-icon shape="check-circle"></clr-icon>
                                    </td>
                                    <td *ngIf="!checkDate(date.date)" class="center">
                                        <clr-icon shape="circle"></clr-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </clr-dg-row-detail>
                </clr-dg-row>

                <clr-dg-footer>{{getTasksByTechnicianId(detailData.id).length}} Aufträge</clr-dg-footer>
            </clr-datagrid>
        </div>
        <div class="modal-footer">
            <button (click)="detailModal = !detailModal" class="btn btn-outline" type="button">Ok</button>
        </div>
    </clr-modal>
</div>