<div class="login-wrapper">
  <div class="login">
    <section class="title">
      <h3 class="welcome">Willkommen bei deinem</h3>
      Dental Lab Manager
      <h5 class="hint">Registriere dich für unser Portal</h5>
    </section>
    <div class="login-group">
      <clr-input-container>
        <label class="clr-sr-only">Vorname</label>
        <input type="email" name="firstname" clrInput placeholder="Vorname" [(ngModel)]="firstName" />
      </clr-input-container>
      <clr-input-container>
        <label class="clr-sr-only">Nachname</label>
        <input type="email" name="lastname" clrInput placeholder="Nachname" [(ngModel)]="lastName" />
      </clr-input-container>
      <clr-input-container>
        <label class="clr-sr-only">Firma</label>
        <input type="email" name="company" clrInput placeholder="Firma" [(ngModel)]="company" />
      </clr-input-container>
      <clr-input-container>
        <label class="clr-sr-only">Email</label>
        <input type="email" name="email" clrInput placeholder="Email" [(ngModel)]="email" />
      </clr-input-container>
      <clr-password-container>
        <label class="clr-sr-only">Passwort</label>
        <input type="password" name="password" clrPassword placeholder="Passwort" [(ngModel)]="password"/>
      </clr-password-container>
      <clr-password-container>
        <label class="clr-sr-only">Passwort wiederholen</label>
        <input type="password" name="password" clrPassword placeholder="Passwort wiederholen" [(ngModel)]="password2"/>
      </clr-password-container>
      <div>
        Vorname Check:
        <clr-icon *ngIf="!checkFirstName" size="24" shape="exclamation-circle" class="is-error"></clr-icon>
        <clr-icon *ngIf="checkFirstName" size="24" shape="check-circle" class="is-success"></clr-icon>
      </div>
      <div>
        Nachname Check:
        <clr-icon *ngIf="!checkLastName" size="24" shape="exclamation-circle" class="is-error"></clr-icon>
        <clr-icon *ngIf="checkLastName" size="24" shape="check-circle" class="is-success"></clr-icon>
      </div>
      <div>
        Email Check:
        <clr-icon *ngIf="!checkEmail" size="24" shape="exclamation-circle" class="is-error"></clr-icon>
        <clr-icon *ngIf="checkEmail" size="24" shape="check-circle" class="is-success"></clr-icon>
      </div>
      <div>
        Firma Check:
        <clr-icon *ngIf="!checkCompany" size="24" shape="exclamation-circle" class="is-error"></clr-icon>
        <clr-icon *ngIf="checkCompany" size="24" shape="check-circle" class="is-success"></clr-icon>
      </div>
      <div>
        Passwort Check:
        <clr-icon *ngIf="!checkPassword" size="24" shape="exclamation-circle" class="is-error"></clr-icon>
        <clr-icon *ngIf="checkPassword" size="24" shape="check-circle" class="is-success"></clr-icon>
      </div>
      <div>
        Passwort wiederholen Check:
        <clr-icon *ngIf="!checkSecondPassword" size="24" shape="exclamation-circle" class="is-error"></clr-icon>
        <clr-icon *ngIf="checkSecondPassword" size="24" shape="check-circle" class="is-success"></clr-icon>
      </div>
      <button (click)="signUp()" [disabled]="!checkIfCanSignUp" class="btn btn-primary">REGISTRIEREN</button>
      <a routerLink='/login' class="signup">Login</a>
    </div>
  </div>
</div>
