import { Component, OnInit } from '@angular/core';
import { Dentist } from 'src/app/models/dentist.interface';
import { DataService } from 'src/app/services/data.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-dentist-content',
  templateUrl: './dentist-content.component.html',
  styleUrls: ['./dentist-content.component.scss']
})
export class DentistContentComponent implements OnInit {

  dentists: Dentist[] = [];

  addModal: boolean;
  patchModal: boolean;
  detailData: Dentist;

  constructor(
    private data: DataService,
  ) { }

  ngOnInit(): void {
    this.data.refreshDentists().then();
    this.data.currentDentists.subscribe(v => this.dentists = v);
  }

  async updateDentist(): Promise<void> {
    await this.data.updateDentist(this.detailData);
    this.detailData = null;
  }

  openPatchModal(dentist: Dentist): void {
    this.detailData = dentist;
    this.patchModal = true;
  }

  async createDentist(name: string, location: string): Promise<void> {
    await this.data.createDentist(name, location);
    this.addModal = false;
  }

}
