<div class="clr-row clr-justify-content-between">
    <div class="clr-col-10 clr-align-self-center">
        <h3 class="pa-5">
            Techniker
        </h3>
        <button (click)="addModal = !addModal" class="btn btn-sm">Neuen Techniker
            hinzufügen</button>
        <clr-datagrid>
            <clr-dg-column>Bild</clr-dg-column>
            <clr-dg-column>Techniker ID</clr-dg-column>
            <clr-dg-column>Vorname</clr-dg-column>
            <clr-dg-column>Nachname</clr-dg-column>
            <clr-dg-column>Pin-Code</clr-dg-column>
            <clr-dg-column>Labor</clr-dg-column>
            <clr-dg-column>Status</clr-dg-column>
            <clr-dg-column>Arbeitsstelle</clr-dg-column>

            <clr-dg-row (click)="openPatchModal(tec)" *ngFor="let tec of technicians">
                <clr-dg-cell><img src="{{ tec.profileImageUrl }}" alt="Bild" style="
                    vertical-align: middle;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    "></clr-dg-cell>
                <clr-dg-cell>{{tec.technicianId}}</clr-dg-cell>
                <clr-dg-cell>{{tec.firstName}}</clr-dg-cell>
                <clr-dg-cell>{{tec.lastName}}</clr-dg-cell>
                <clr-dg-cell>{{tec.code}}</clr-dg-cell>
                <clr-dg-cell>{{tec.lab.labName}}</clr-dg-cell>
                <clr-dg-cell *ngIf="tec.status == 'ACTIVE'"><span class="label label-success">{{tec.status}}</span>
                </clr-dg-cell>
                <clr-dg-cell *ngIf="tec.status == 'INACTIVE'"><span class="label label-danger">{{tec.status}}</span>
                </clr-dg-cell>
                <clr-dg-cell>{{+tec.job * 100}} %</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>{{technicians.length}} Techniker</clr-dg-footer>
        </clr-datagrid>
    </div>

    <clr-modal [(clrModalOpen)]="addModal" [clrModalClosable]="false">
        <h3 class="modal-title">Neuen Techniker hinzufügen</h3>
        <div class="modal-body">

            <clr-input-container>
                <label>Techniker ID</label>
                <input clrInput name="name" #technicianId />
            </clr-input-container>
            <clr-input-container>
                <label>Vorname</label>
                <input clrInput name="name" #firstName />
            </clr-input-container>
            <clr-input-container>
                <label>Nachname</label>
                <input clrInput name="name" #lastName />
            </clr-input-container>
            <clr-input-container>
                <label>Profilbild</label>
                <input clrInput name="name" #profileImageUrl />
            </clr-input-container>
            <clr-select-container>
                <label>Labor</label>
                <select clrSelect name="options" #labId>
                    <option *ngFor="let item of labs" value="{{item.id}}">{{item.labName}}</option>
                </select>
            </clr-select-container>
            <clr-select-container>
                <label>Status</label>
                <select clrSelect name="options" #status>
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                </select>
            </clr-select-container>
        </div>
        <div class="modal-footer">
            <button (click)="addModal = !addModal" class="btn btn-outline" type="button">Zurück</button>
            <button
                (click)="createTechnician(technicianId.value, firstName.value,profileImageUrl.value,lastName.value, labId.value)"
                class="btn btn-primary" type="button">Speichern</button>
        </div>
    </clr-modal>

    <div *ngIf="detailData">
        <clr-modal [(clrModalOpen)]="patchModal" [clrModalClosable]="false">
            <h3 class="modal-title">{{ detailData.firstName }} {{ detailData.lastName }} bearbeiten</h3>
            <div class="modal-body">
                <img src="{{ detailData.profileImageUrl }}" alt="Bild" style="
                vertical-align: middle;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                ">
                <clr-input-container>
                    <label>Techniker ID</label>
                    <input clrInput name="name" value="{{ detailData.technicianId }}"
                        [(ngModel)]="detailData.technicianId" />
                </clr-input-container>
                <clr-input-container>
                    <label>Vorname</label>
                    <input clrInput name="name" value="{{ detailData.firstName }}" [(ngModel)]="detailData.firstName" />
                </clr-input-container>
                <clr-input-container>
                    <label>Nachname</label>
                    <input clrInput name="name" value="{{ detailData.lastName }}" [(ngModel)]="detailData.lastName" />
                </clr-input-container>
                <clr-input-container>
                    <label>Pin-Code</label>
                    <input clrInput name="name" value="{{ detailData.code }}" [(ngModel)]="detailData.code" />
                </clr-input-container>
                <clr-input-container>
                    <label>Job 1 = Vollzeit</label>
                    <input clrInput name="name" value="{{ detailData.job }}" [(ngModel)]="detailData.job" />
                </clr-input-container>
                <clr-input-container>
                    <label>Profilbild</label>
                    <input clrInput name="name" value="{{ detailData.profileImageUrl }}"
                        [(ngModel)]="detailData.profileImageUrl" />
                </clr-input-container>
                <clr-select-container>
                    <label>Labor</label>
                    <select clrSelect name="options" value="{{ detailData.labId }}" [(ngModel)]="detailData.labId">
                        <option *ngFor="let item of labs" value="{{item.id}}">{{item.labName}}</option>
                    </select>
                </clr-select-container>
                <clr-select-container>
                    <label>Status</label>
                    <select clrSelect name="options" value="{{ detailData.status }}" [(ngModel)]="detailData.status">
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                    </select>
                </clr-select-container>
            </div>
            <div class="modal-footer">
                <button (click)="patchModal = !patchModal" class="btn btn-outline" type="button">Zurück</button>
                <button (click)="updateTechnician()" class="btn btn-primary" type="button">Speichern</button>
            </div>
        </clr-modal>
    </div>