import { Component, OnInit } from '@angular/core';
import { Lab } from 'src/app/models/lab.interface';
import { TechnicianStatus } from 'src/app/models/technician-status.enum';
import { Technician } from 'src/app/models/technician.interface';
import { DataService } from 'src/app/services/data.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-technician-content',
  templateUrl: './technician-content.component.html',
  styleUrls: ['./technician-content.component.scss']
})
export class TechnicianContentComponent implements OnInit {

  technicians: Technician[] = [];
  labs: Lab[] = [];

  addModal: boolean;
  patchModal: boolean;
  detailData: Technician;

  constructor(
    private data: DataService,
  ) { }

  ngOnInit(): void {
    this.data.refreshTechnicians();
    this.data.currentTechnicians.subscribe(v => this.technicians = v);

    this.data.refreshLabs();
    this.data.currentLabs.subscribe(v => this.labs = v);
  }

  async updateTechnician(): Promise<void> {
    await this.data.updateTechnician(this.detailData);
    this.detailData = null;
  }

  openPatchModal(technician: Technician): void {
    this.detailData = technician;
    this.patchModal = true;
  }

  async createTechnician(technicianId: string, firstName: string, profileImageUrl: string, lastName: string, labId: number): Promise<void> {
    await this.data.createTechnician(technicianId, firstName, profileImageUrl, lastName, labId);
    this.addModal = false;
  }

}
