<h1>Chargenmanagment</h1>
<clr-tabs>
    <clr-tab>
        <button clrTabLink>Suche</button>
        <clr-tab-content>
            <h1>Chargensuche</h1>
            <div>

                <clr-input-container>
                    <label>Chargensuche</label>
                    <input (ngModelChange)="searchBatch()" clrInput placeholder="..." name="search"
                        [(ngModel)]="search" />
                </clr-input-container>
                <button (click)="getBatchSearchData()" [disabled]="searchButtonDisabled"
                    class="btn btn-sm">Suchen</button>
                <button (click)="exportAsXLSX()" [disabled]="(searchResults.length == 0)"
                    class="btn btn-sm btn-primary">Export
                    Excel</button>
            </div>

            <clr-datagrid>
                <clr-dg-placeholder>
                    <clr-spinner *ngIf="searching" [clrInline]="true">Loading ...</clr-spinner>
                </clr-dg-placeholder>
                <clr-dg-column>Produkt</clr-dg-column>
                <clr-dg-column>Charge</clr-dg-column>
                <clr-dg-column>Patient</clr-dg-column>
                <clr-dg-column>Arbeit</clr-dg-column>
                <clr-dg-column>Zahnarzt</clr-dg-column>
                <clr-dg-row *ngFor="let data of searchResults">
                    <clr-dg-cell>{{ data.productName }}</clr-dg-cell>
                    <clr-dg-cell>
                        <span>{{searchTermMarker(data.lot)[0]}}<strong>{{searchTermMarker(data.lot)[1]}}</strong>{{searchTermMarker(data.lot)[2]}}</span>
                    </clr-dg-cell>
                    <clr-dg-cell>{{ data.patient }}</clr-dg-cell>
                    <clr-dg-cell>{{ data.description }}</clr-dg-cell>
                    <clr-dg-cell>{{ data.dentist }}</clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>{{ searchResults.length }} Suchergebnisse</clr-dg-footer>
            </clr-datagrid>
        </clr-tab-content>
    </clr-tab>
    <clr-tab>

        <button clrTabLink>Produkte</button>
        <clr-tab-content>
            <div class="clr-row">
                <div class="clr-col-6">
                    <h3>Produkte</h3>
                    <clr-dg-action-bar>
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-secondary"
                                (click)="addBatchProductModal = !addBatchProductModal">
                                <clr-icon shape="plus"></clr-icon> Produkt hinzufügen
                            </button>

                            <button *ngIf="singleSelectedProduct" (click)="openBatchPatchModal(singleSelectedProduct)" type="button" class="btn btn-sm btn-secondary">

                                    <clr-icon shape="close"></clr-icon> {{singleSelectedProduct?.productName}}
                                    bearbeiten

                            </button>

                        </div>
                    </clr-dg-action-bar>
                    <clr-datagrid [(clrDgSingleSelected)]="singleSelectedProduct">
                        <clr-dg-column>Edit</clr-dg-column>
                        <clr-dg-column [clrDgField]="'productName'">Produkt</clr-dg-column>
                        <clr-dg-column>Type</clr-dg-column>

                        <clr-dg-row *clrDgItems="let data of batchProducts" [clrDgItem]="data">
                            <clr-dg-cell>Edit</clr-dg-cell>
                            <clr-dg-cell (click)="getBatchesByProductId(data.id)">{{ data.productName }}</clr-dg-cell>
                            <clr-dg-cell *ngIf="data.productType == 'SELF_GENERATED'"><span class="label">Selbst
                                    generiert</span>
                            </clr-dg-cell>
                            <clr-dg-cell *ngIf="data.productType == 'HIBC'"><span class="label">HIBC</span>
                            </clr-dg-cell>
                        </clr-dg-row>

                        <clr-dg-footer>{{ batchProducts.length }} Produkte</clr-dg-footer>
                    </clr-datagrid>
                </div>
                <div class="clr-col-6">
                    <h3>Produkt Chargen - {{selectedBatchProduct?.productName}}</h3>
                    <clr-datagrid [(clrDgSelected)]="selected">
                        <clr-dg-action-bar>
                            <div class="btn-group">
                                <button [disabled]="selectedBatchProduct == null" type="button"
                                    class="btn btn-sm btn-secondary" (click)="addBatchModal=!addBatchModal">
                                    <clr-icon shape="plus"></clr-icon> Charge hinzufügen
                                </button>
                            </div>
                            <div class="btn-group">
                                <clr-dropdown>
                                    <button type="button" class="btn btn-sm btn-secondary" clrDropdownTrigger>
                                        Drucken
                                        <clr-icon shape="caret down"></clr-icon>
                                    </button>
                                    <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                                        <button type="button" (click)="testMultiPrint()"
                                            [disabled]="selected.length === 0" clrDropdownItem>
                                            Ausgewählte Drucken
                                        </button>
                                    </clr-dropdown-menu>
                                </clr-dropdown>
                            </div>
                        </clr-dg-action-bar>
                        <clr-dg-column [clrDgField]="'product.productName'">Produkt</clr-dg-column>
                        <clr-dg-column [clrDgField]="'bestBefore'">Haltbarkeit</clr-dg-column>
                        <clr-dg-column [clrDgField]="'lot'">Charge</clr-dg-column>
                        <clr-dg-column>Barcode</clr-dg-column>
                        <clr-dg-row
                          (click)="openBatchPatchModal(batch)"
                            [ngStyle]="{'background-color': compareDates(batch.createdAt) ? 'rgb(233, 125, 125)' :'' }"
                            *clrDgItems="let batch of productBatches" [clrDgItem]="batch">
                            <clr-dg-cell>{{ batch.product.productName }}</clr-dg-cell>
                            <clr-dg-cell>{{ batch.bestBefore | date }}</clr-dg-cell>
                            <clr-dg-cell>{{ batch.lot }}</clr-dg-cell>
                            <clr-dg-cell (click)="downloadAsPDF(batch)">
                                <div style="width: 30px; height: 30px" id="barcode-{{ batch.id }}">
                                    <qrcode-svg value="{{ batch.id }}"></qrcode-svg>
                                </div>
                            </clr-dg-cell>
                        </clr-dg-row>
                        <clr-dg-footer>{{ productBatches.length }} Chargen</clr-dg-footer>
                    </clr-datagrid>
                </div>
            </div>


        </clr-tab-content>
    </clr-tab>
    <!-- <clr-tab>
        <button clrTabLink>Chargen</button>
        <clr-tab-content>
            <h3>Chargen</h3>
            <clr-datagrid [(clrDgSelected)]="selected">
                <clr-dg-action-bar>
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-secondary" (click)="addBatchModal=!addBatchModal">
                            <clr-icon shape="plus"></clr-icon> Charge hinzufügen
                        </button>
                    </div>
                    <div class="btn-group">
                        <clr-dropdown>
                            <button type="button" class="btn btn-sm btn-secondary" clrDropdownTrigger>
                                Drucken
                                <clr-icon shape="caret down"></clr-icon>
                            </button>
                            <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                                <button type="button" (click)="testMultiPrint()" [disabled]="selected.length === 0"
                                    clrDropdownItem>
                                    Ausgewählte Drucken
                                </button>
                            </clr-dropdown-menu>
                        </clr-dropdown>
                    </div>
                </clr-dg-action-bar>
                <clr-dg-column [clrDgField]="'product.productName'">Produkt</clr-dg-column>
                <clr-dg-column [clrDgField]="'bestBefore'">Haltbarkeit</clr-dg-column>
                <clr-dg-column [clrDgField]="'lot'">Charge</clr-dg-column>
                <clr-dg-column>Barcode</clr-dg-column>
                <clr-dg-row *clrDgItems="let batch of batches" [clrDgItem]="batch">
                    <clr-dg-cell>{{ batch.product.productName }}</clr-dg-cell>
                    <clr-dg-cell>{{ batch.bestBefore | date }}</clr-dg-cell>
                    <clr-dg-cell>{{ batch.lot }}</clr-dg-cell>
                    <clr-dg-cell (click)="downloadAsPDF(batch)">
                        <div style="width: 30px; height: 30px" id="barcode-{{ batch.id }}">
                            <qrcode-svg value="{{ batch.id }}"></qrcode-svg>
                        </div>
                    </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>{{ batches.length }} Chargen</clr-dg-footer>
            </clr-datagrid>
        </clr-tab-content>
    </clr-tab> -->
</clr-tabs>

<clr-modal [(clrModalOpen)]="addBatchModal" [clrModalClosable]="false">
    <h3 class="modal-title">Charge hinzufügen - {{selectedBatchProduct?.productName}}</h3>
    <div class="modal-body">
        <clr-input-container>
            <label>LOT</label>
            <input clrInput name="name" value="" #lot />
        </clr-input-container>
        <clr-date-container>
            <label>Haltbarkeitsdatum</label>
            <input type="date" clrDate name="demo" #date />
        </clr-date-container>
    </div>
    <div class="modal-footer">
        <button (click)="addBatchModal = !addBatchModal" class="btn btn-outline" type="button">
            Zurück
        </button>
        <button [disabled]="lot.value == ''" (click)="createBatch(date.value, lot.value)" class="btn btn-primary"
            type="button">
            Speichern
        </button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="addBatchProductModal" [clrModalClosable]="false">
    <h3 class="modal-title">Produkt hinzufügen</h3>
    <div class="modal-body">
        <clr-input-container>
            <label>Produktname</label>
            <input clrInput name="name" value="" #name />
        </clr-input-container>
    </div>
    <div class="modal-footer">
        <button (click)="addBatchProductModal = !addBatchProductModal" class="btn btn-outline" type="button">
            Zurück
        </button>
        <button (click)="createBatchProduct(name.value)" class="btn btn-primary" type="button">
            Speichern
        </button>
    </div>
</clr-modal>

<div *ngIf="detailBatch">
    <clr-modal [(clrModalOpen)]="detailBatchModal" [clrModalClosable]="false">
        <h3 class="modal-title">{{ detailBatch.product.productName }} - {{ detailBatch.lot }} bearbeiten</h3>
        <div class="modal-body">
          <clr-input-container>
            <label>Produkt</label>
            <input clrInput name="name" value="{{ detailBatch.product.id }}" [(ngModel)]="detailBatch.product.id" />
          </clr-input-container>
            <clr-input-container>
                <label>UID</label>
                <input clrInput name="name" value="{{ detailBatch.uid }}" [(ngModel)]="detailBatch.uid" />
            </clr-input-container>
          <clr-input-container>
            <label>LOT</label>
            <input clrInput name="name" value="{{ detailBatch.lot }}" [(ngModel)]="detailBatch.lot" />
          </clr-input-container>
     <clr-date-container>
                <label>Haltbarkeitsdatum</label>
                <input type="date" [(clrDate)]="detailBatch.bestBefore" />
            </clr-date-container>
        </div>
        <div class="modal-footer">
            <button (click)="detailBatchModal = !detailBatchModal" class="btn btn-outline" type="button">
                Zurück
            </button>
            <button (click)="updateBatch()" class="btn btn-primary" type="button">
                Speichern
            </button>
        </div>
    </clr-modal>
</div>

<!--
<div *ngIf="selectedBatch">
  <clr-modal [(clrModalOpen)]="selectedBatchModal" [clrModalClosable]="false">
    <h3 class="modal-title">{{ selectedBatch.uid }} bearbeiten</h3>
    <div class="modal-body">
      <clr-input-container>
        <label>UID</label>
        <input clrInput name="name" value="{{ selectedBatch.uid }}" [(ngModel)]="selectedBatch.uid" />
      </clr-input-container>
      <clr-date-container>
        <label>Haltbarkeitsdatum</label>
        <input type="date" clrDate name="demo" [(ngModel)]="selectedBatch.lot" />
      </clr-date-container>
    </div>
    <div class="modal-footer">
      <button (click)="selectedBatchModal = !selectedBatchModal" class="btn btn-outline" type="button">
        Zurück
      </button>
      <button (click)="updateBatch()" class="btn btn-primary" type="button">
        Speichern
      </button>
    </div>
  </clr-modal>
</div>-->
