import { getLocaleId } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptions, EventInput } from '@fullcalendar/angular';
import { NotificationsService } from 'angular2-notifications';
import { CalenderEntry } from 'src/app/models/calender-entry.interface';
import { CalenderDateRange } from 'src/app/models/calenderDate.interface';
import { TaskDate } from 'src/app/models/taskDate.interface';
import { User } from 'src/app/models/user.interface';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { DateService } from 'src/app/services/date.service';
import { RestService } from 'src/app/services/rest.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { addDays, format, formatDistance, formatRelative, subDays } from 'date-fns';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  calendarOptions: CalendarOptions;
  collapsed;
  login = false;

  labOwner: User;

  dates: TaskDate[];
  calenderEntry: EventInput[] = [];
  calenderDateRange: CalenderDateRange[] = [];

  constructor(
    private data: DataService,
    private authService: AuthService,
    private router: Router,
    private webSocketService: WebsocketService,
    public dateService: DateService,
  ) { }


  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.login = true;

      this.data.refreshUser();
      this.data.currentUser.subscribe(v => this.labOwner = v);

      this.webSocketService.startSocket();

      this.data.refreshCalenderDateRange();
      this.data.currentCalenderDateRanges.subscribe(c => this.calenderDateRange = c);
      this.data.refreshTaskDates();
      this.data.currentTaskDate.subscribe(v => {
        this.dates = v;
        const entry: EventInput[] = [];
        this.dates.forEach(e => {
          if (new Date(e.date).getTime() + 3600000 > new Date().getTime()) {
            const singleEntry: EventInput = {
              id: e.id.toString(),
              title: e.calenderDescription,
              color: e.calenderColor,
              editable: true,
              date: e.date.toString(),
              description: 'first description'
            };
            entry.push(singleEntry);
          }

        });
        this.calenderDateRange.forEach(x => {
          const singleCalenderDateRange: EventInput = {
            id: x.id.toString(),
            title: x.description,
            color: x.color,
            editable: false,
            start: format(new Date(x.from), 'yyyy-MM-dd'),
            end: format(addDays(new Date(x.to), 1), 'yyyy-MM-dd'),
          };
          entry.push(singleCalenderDateRange);
        });
        this.calenderEntry = entry;
        this.calendarOptions = {
          allDayText: 'Heute',
          initialView: 'list',
          headerToolbar: false,
          height: 1000,
          locale: 'de',
          themeSystem: 'standard',
          events: this.calenderEntry,
        };
      });
    } else {
      this.router.navigate(['/login']);
    }
  }


  logOut(): void {
    this.authService.logOut();
    this.router.navigate(['/login']);
  }

}
