export enum TaskStatus {
    UNKNOWN = 'UNKNOWN',
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    NOTINHOUSE = 'NOTINHOUSE',
    INACTIVE = 'INACTIVE',
}

export enum TaskLocationStatus {
    UNKNOWN = 'UNKNOWN',
    INHOUSE = 'INHOUSE',
    NOTINHOUSE = 'NOTINHOUSE',
    DENTIST = 'DENTIST'
}
