import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoginRes} from '../models/loginResponce.interface';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private REST_API_SERVER = environment.APIEndpoint;

  constructor(
    private http: HttpClient,
  ) {

  }

  signIn(email: string, password: string): Promise<LoginRes> {
    const body = JSON.stringify({
      email,
      password
    });
    console.log(body);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<LoginRes>(this.REST_API_SERVER + '/auth/signin', body, { headers }).toPromise();
  }

  signUp(firstName: string, lastName: string, company: string, email: string, password: string): Promise<any> {
    const body = JSON.stringify({
      firstName,
      lastName,
      company,
      email,
      password
    });
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<any>(this.REST_API_SERVER + '/auth/signup', body, { headers }).toPromise();
  }

  logOut(): void {
    localStorage.removeItem('id_token');
  }

  setSession(token: string): void {
    localStorage.setItem('id_token', token);
  }

  public isLoggedIn(): boolean {
    const d = Date.now() / 1000;
    const n = Math.floor(d);
    const exp = this.getExpiration();

    return exp > n;
  }

  getLocalStorageToken(): string {
    const token = localStorage.getItem('id_token');
    if (token) {
      return token;
    } else {
      return '';
    }
  }

  getExpiration(): number {
    try {
      const jwt = this.getLocalStorageToken();

      const json: any = this.decode(jwt);

      return +json.exp;
    } catch (error) {

      return 0;
    }

  }

  decode(token: string): any {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {

    }
}

}
