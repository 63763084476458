<div>
 <!--   <h3>CalenderStatus</h3>
    <input type="text" #description> <br>
    <input type="color" value="#ffffff" #color> <br>
    <button class="btn" (click)="createCalenderStatus(description.value, color.value)">Speichern</button>


    <li *ngFor="let item of calenderStatuses">
        {{ item.statusDescription }}
        <div [ngStyle]="{'color': item.color}" style="height: 50px; width: 50px;">
            ###########
        </div>
    </li>
</div>

<div>
    <h3>TaskDate</h3>
    <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date Time" #date>
    <owl-date-time #dt1></owl-date-time>

    <clr-select-container>
        <label>Calender Date Status </label>
        <select clrSelect name="options" #calenderStatus>
            <option *ngFor="let item of calenderStatuses" value={{item.id}}>
                {{item.statusDescription}}</option>
        </select>
    </clr-select-container>

    <clr-select-container>
        <label>Task </label>
        <select clrSelect name="options" #taskId>
            <option *ngFor="let item of tasks" value={{item.id}}>
                {{item.patient}} - {{item.description}}</option>
        </select>
    </clr-select-container>
    <button class="btn" (click)="createTaskDate(date.value,taskId.value, calenderStatus.value)">Speichern</button>
    <li *ngFor="let item of taskDates">
        {{ item.date }}
    </li>
</div>

<div>
    <h3>Dentist</h3>
    <input type="text" #name> <br>
    <input type="text" #location> <br>

    <button class="btn" (click)="createDentist(name.value,location.value)">Speichern</button>
    <li *ngFor="let item of dentists">
        {{ item.name }} - {{ item.location }}
    </li>
</div>

<div>
    <h3>Technician</h3>
    <label>technicianId</label>
    <input type="text" #technicianId> <br>
    <label>firstName</label>
    <input type="text" #firstName> <br>
    <label>lastName</label>
    <input type="text" #lastName> <br>
    <clr-select-container>
        <label>Lab</label>
        <select clrSelect name="options" #lab>
            <option *ngFor="let item of labs" value={{item.id}}>
                {{item.labName}}</option>
        </select>
    </clr-select-container>
    <button class="btn"
        (click)="createTechnician(technicianId.value, firstName.value, 'profileImageUrl', lastName.value, lab.value)">Speichern</button>
    <li *ngFor="let item of technicians">
        {{ item.technicianId }} - {{ item.firstName }} - {{ item.lastName }}
    </li>
</div>

<div>
    <h3>Labs</h3>
    <label>LabName</label>
    <input type="text" #labName> <br>
    <button class="btn" (click)="createLab(labName.value)">Speichern</button>
    <li *ngFor="let item of labs">
        {{ item.labName }}
    </li>
</div>

<div>
    <h3>Tasks</h3>
    <label>uid</label>
    <input type="text" #uid> <br>
    <label>taskDesc</label>
    <input type="text" #taskDesc> <br>
    <label>taskPat</label>
    <input type="text" #taskPat> <br>

    <clr-select-container>
        <label>Dentists</label>
        <select clrSelect name="options" #dentTaskID>
            <option *ngFor="let item of dentists" value={{item.id}}>
                {{item.name}}</option>
        </select>
    </clr-select-container>

    <clr-select-container>
        <label>Technicians</label>
        <select clrSelect name="options" #techTaskID>
            <option *ngFor="let item of technicians" value={{item.id}}>
                {{item.id}} -  {{item.technicianId}} - {{item.firstName}} {{item.lastName}}</option>
        </select>
    </clr-select-container>

    <button class="btn"
        (click)="createTask(uid.value,taskDesc.value,taskPat.value,dentTaskID.value,techTaskID.value)">Speichern</button>
    <li *ngFor="let item of tasks">
        {{ item.patient }} - {{ item.description }} - {{ item.uid }}
    </li>-->
</div>
