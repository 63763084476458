<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<div class="clr-row clr-align-items-end">

    <div class="clr-row-3">
        <clr-date-container>
            <label>Datum</label>
            <input (clrDateChange)="dateSelect($event)" clrDate placeholder="Pick a date" name="today"
                [(clrDate)]="today" />
        </clr-date-container>
    </div>
    <div class="clr-row-3 clr-justify-content-center">
        <h4>Termine: {{(eventCount) ? eventCount: 0}}</h4>
    </div>
</div>

<button *ngIf="showAllDays" (click)="showAllDaySlot()" class="btn btn-primary btn-sm">
    Tagestermine ausblenden
</button>
<button *ngIf="!showAllDays" (click)="showAllDaySlot()" class="btn  btn-sm">
    Tagestermine einblenden
</button>
<button (click)="addModal = !addModal" class="btn btn-primary btn-sm">
    Neuen Termin hinzufügen
</button>

<button *ngIf="!editMode" (click)="activateEditMode()" class="btn btn-sm">
    Bearbeitungs-Modus Aktivieren
</button>
<button *ngIf="editMode" (click)="deActivateEditMode()" class="btn btn-sm btn-warning">
    Bearbeitungs-Modus Deaktivieren
</button>
<button *ngIf="dateSelectArg" (click)="generalDateModal = !generalDateModal" class="btn btn-sm">Termin hinzufügen:
    {{dateSelectArg.start | date:'medium'}}</button>

<full-calendar #calendar [options]="calendarOptions"></full-calendar>

<div style="z-index: 100;" id="tooltip">
    <div @fade *ngIf="hover && !editMode">
        <ng-container *ngIf="smallTaskDetail; else elseTemplate">
            <div class="card">
                <div *ngIf="smallTaskDetail" class="card-header">
                    <div><strong>{{ getDateString(taskDateDetail.date) }}</strong> - {{smallTaskDetail.description}}
                    </div>
                </div>
                <div *ngIf="!smallTaskDetail" class="card-header">
                    <div> Art</div>
                </div>
                <div [ngStyle]="{'background-color':  taskDateDetail.calenderColor }" style="height: 5px;">
                </div>
                <div class="card-block">
                    <div class="card-media-block">
                        <img *ngIf="smallTaskDetail"
                            src="{{ getTechnician(smallTaskDetail?.taskOwnerId ).profileImageUrl }}" alt="Bild" style="
                                        vertical-align: middle;
                                        width: 70px;
                                        height: 70px;
                                        border-radius: 50%;
                                        ">
                        <div *ngIf="!smallTaskDetail"
                            style="height: 70px;width: 70px;border-radius: 50%; background-color: rgb(252, 252, 252);">

                        </div>
                        <div class="card-media-description">
                            <span *ngIf="smallTaskDetail" class="card-media-title">
                                Termin: {{getCalenderStatusString(taskDateDetail.calenderStatusId)}}
                            </span>
                            <span *ngIf="!smallTaskDetail" class="card-media-title">
                                Termin:
                            </span>
                            <span *ngIf="smallTaskDetail" class="card-media-title">
                                Patient: {{smallTaskDetail.patient}}
                            </span>
                            <span *ngIf="!smallTaskDetail" class="card-media-title">
                                Patient:
                            </span>
                            <span *ngIf="smallTaskDetail" class="card-media-text">
                                Zahnarzt: {{smallTaskDetail.dentist.name}}
                            </span>
                            <span *ngIf="!smallTaskDetail" class="card-media-text">
                                Zahnarzt:
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <ng-container *ngIf="taskDateRangeDetail; else elseTemplate2">
                <div class="card">
                    <div *ngIf="taskDateRangeDetail" class="card-header">
                        <div><strong>{{ taskDateRangeDetail.description }}</strong>
                        </div>
                    </div>
                    <div [ngStyle]="{'background-color':  taskDateRangeDetail.color }" style="height: 5px;">
                    </div>
                    <div class="card-block">
                        <div class="card-media-block">
                            <div class="card-media-description">
                                <span class="card-media-title">
                                    Von: {{taskDateRangeDetail.from | date:'short'}}
                                </span>
                                <span class="card-media-title">
                                    Bis: {{taskDateRangeDetail.to | date:'short'}}
                                </span>
                                <span class="card-media-text">
                                    Regel: {{ rRuleStringToText(taskDateRangeDetail.rRule) }}
                                </span>
                                <span *ngIf="taskDateRangeDetail.notes != null" class="card-media-text">
                                    Notiz: {{ taskDateRangeDetail.notes }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplate2>
                <div class="card">
                    <div *ngIf="generalDateDetail" class="card-header">
                        <div><strong>{{ generalDateDetail?.description }}</strong>
                        </div>
                    </div>
                    <div [ngStyle]="{'background-color':  generalDateDetail?.calenderColor }" style="height: 5px;">
                    </div>
                    <div class="card-block">
                        <div class="card-media-block">
                            <div class="card-media-description">
                                <span class="card-media-title">
                                    Datum: {{generalDateDetail?.date | date:'short'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>


        </ng-template>
    </div>
</div>


<clr-modal [(clrModalOpen)]="addModal">
    <h3 class="modal-title">Neuen Termin hinzufügen</h3>
    <div class="modal-body">
        <clr-select-container>
            <label>Kalender Status</label>
            <select clrSelect name="options" #statusId>
                <option *ngFor="let item of calenderStatus" value="{{ item.id }}">
                    {{ item.statusDescription }}
                </option>
            </select>
        </clr-select-container>

        <clr-combobox-container>
            <label>Patient</label>
            <clr-combobox [(ngModel)]="taskSelection" name="myState" required>
                <clr-options>
                    <clr-option *clrOptionItems="let task of tasks; field: 'patient'" [clrValue]="task">
                        <clr-icon shape="user" role="img" aria-label="patient"></clr-icon>
                        {{ task.patient }}
                    </clr-option>
                </clr-options>
            </clr-combobox>
        </clr-combobox-container>

        <clr-date-container>
            <input type="date" clrDate name="demo" [(ngModel)]="selectedDate" min="2020-11-17" />
        </clr-date-container>
        <h3>{{ this.getTimeString() }}</h3>
        <strong>Stunden</strong>
        <div>
            <a (click)="hours = item" *ngFor="let item of this.dateService.hourArray"
                [ngClass]="item == this.hours ? 'label-warning' : 'label-purple'" class="label clickable">{{ item }}</a>
        </div>
        <strong>Minuten</strong>
        <div>
            <a (click)="minutes = item" *ngFor="let item of this.dateService.minutesArray"
                [ngClass]="item == this.minutes ? 'label-warning' : 'label-purple'" class="label clickable">{{ item
                }}</a>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="addModal = !addModal" class="btn btn-outline" type="button">
            abbrechen
        </button>
        <button (click)="createTaskDate(statusId.value)" class="btn btn-primary" type="button">
            Speichern
        </button>
    </div>
</clr-modal>

<div *ngIf="taskDetail">
    <clr-modal [(clrModalOpen)]="detailModal">
        <div class="modal-body">
            <h2 class="modal-title">{{ taskDetail.description }}</h2>
            <app-task-view-detail [technicians]="this.technicians" [taskDetail]="this.taskDetail">
            </app-task-view-detail>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="detailModal = false">
                    Ok
                </button>
            </div>
        </div>
    </clr-modal>
</div>

<div *ngIf="taskDateDetail">
    <clr-modal [(clrModalOpen)]="taskDetailModal">
        <h3 class="modal-title">{{ taskDateDetail.calenderDescription }}</h3>
        <div class="modal-body">
            <button (click)="deleteDate(taskDateDetail)" class="btn btn-warning">Termin löschen</button>
            <clr-select-container>
                <label>Status</label>
                <select clrSelect name="options" value="{{ taskDateDetail.calenderStatusId }}"
                    [(ngModel)]="taskDateDetail.calenderStatusId">
                    <option *ngFor="let item of calenderStatus" value="{{ item.id }}">
                        {{ item.statusDescription }}
                    </option>
                </select>

            </clr-select-container>
            <br>
            <div>

                <ng-container *ngIf="taskDetail?.locationStatus == 'INHOUSE'; else elseTemplate">
                    <button (click)="updateTaskLocationStatus()" class="btn">
                        Im Haus<clr-icon shape="house" title="house"></clr-icon>
                    </button>

                </ng-container>
                <ng-template #elseTemplate>
                    <button (click)="updateTaskLocationStatus()" class="btn">
                        Unterwegs!<clr-icon class="is-solid" shape="car" title="car"></clr-icon>
                    </button>
                </ng-template>


                <!--        <ng-container *ngIf="task.locationStatus == 'INHOUSE'; else elseTemplate">
                        <clr-icon shape="house" size="24"></clr-icon>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <ng-container *ngIf="task.locationStatus == 'NOTINHOUSE'; else elseTemplate">
                            <clr-icon shape="car" size="24" class="is-solid"></clr-icon>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <clr-icon shape="crown" size="24" class="is-solid"></clr-icon>
                        </ng-template>
                    </ng-template>
 -->

            </div>
            <button (click)="taskDateDetail.calenderStatusId = item.id" style="color: #999999;" class="btn btn-sm"
                *ngFor="let item of calenderStatus" [ngStyle]="{'background-color': item.color}">
                {{ item.statusDescription }}
            </button>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline" (click)="taskDetailModal = false">
                Abrechen
            </button>
            <button type="button" class="btn btn-primary" (click)="updateTaskDateStatus()">
                Ändern
            </button>
        </div>
    </clr-modal>
</div>

<div *ngIf="dateSelectArg">
    <clr-modal [(clrModalOpen)]="generalDateModal">
        <h3 class="modal-title">Termin: {{dateSelectArg.start | date:'medium'}}</h3>
        <div class="modal-body">
            <clr-select-container>
                <label>Kalender Status</label>
                <select clrSelect name="options" #statusId>
                    <option *ngFor="let item of calenderStatus" value="{{ item.id }}">
                        {{ item.statusDescription }}
                    </option>
                </select>
            </clr-select-container>
            <clr-input-container>
                <label>Beschreibung</label>
                <input placeholder="Beschreibung" clrInput name="description" required #description />
            </clr-input-container>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline" (click)="generalDateModal = false">Abbrechen</button>
            <button type="button" class="btn btn-primary"
                (click)="createGeneralDate(description.value, statusId.value)">Speichern</button>
        </div>
    </clr-modal>
</div>

<div *ngIf="generalDateDetailPage != null">
    <clr-modal [(clrModalOpen)]="generalDateDetailPageModal">
        <h3 class="modal-title">Termin: {{ generalDateDetailPage.date | date:'medium'}}</h3>
        <div class="modal-body">
            Beschreibung: {{generalDateDetailPage.description}}
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline" (click)="generalDateDetailPageModal = false">Abbrechen</button>
            <button type="button" class="btn btn-danger" (click)="deleteGeneralDate()" >Löschen</button>
        </div>
    </clr-modal>
</div>
