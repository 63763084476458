import { Injectable } from '@angular/core';
import { EventInput } from '@fullcalendar/angular/public_api';
import RRule from 'rrule';
import { CalenderDateRange } from '../models/calenderDate.interface';
import { format, compareAsc, addDays, addMonths, subDays } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  public hourArray: string[] = [
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
  ];
  public minutesArray: string[] = ['00', '15', '30', '45'];

  constructor() {}

  getHourArray(): string[] {
    return this.hourArray;
  }

  getMinutesArray(): string[] {
    return this.minutesArray;
  }

  getTimeString(hour: string, minutes: string): string {
    return hour + ':' + minutes;
  }

  getDateString(date: Date): string {
    return date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear(); // '12/16/2020';
  }

  dateFromHourMinutesDate(hours: number, minutes: number, day: string): Date {
    const split = day.split('.');
    const d = new Date(
      +split[2],
      +split[1] - 1,
      +split[0],
      hours,
      minutes,
      0,
      0
    );
    return d;
  }

  getDaysBetween(from: Date, to: Date): number {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(from.toString()).getTime();
    const secondDate = new Date(to.toString()).getTime();
    return Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;
  }

  getRule(rule: string): any {
    switch (rule) {
      case 'DAILY':
        return RRule.DAILY;
        break;
      case 'WEEKLY':
        return RRule.WEEKLY;
        break;
      case 'MONTHLY':
        return RRule.MONTHLY;
        break;
      case 'YEARLY':
        return RRule.YEARLY;
        break;
      default:
        return RRule.YEARLY;
        break;
    }
  }
  // RRULE:FREQ=MONTHLY;COUNT=12;INTERVAL=1;WKST=MO
  generateRRuleFromStringAndDate(date: string, rule: string): RRule {
    const splitRule = rule.split(';');
    const ruleMap = new Map();
    splitRule.forEach((v) => ruleMap.set(v.split('=')[0], v.split('=')[1]));
    const newDate = new Date(date);
    return new RRule({
      freq: this.getRule(ruleMap.get('RRULE:FREQ')),
      dtstart: newDate,
      count: +ruleMap.get('COUNT'),
      interval: +ruleMap.get('INTERVAL'),
    });
  }

  getRRDates(date: string, rule: string): Date[] {
    const rRule = this.generateRRuleFromStringAndDate(date, rule);
    return rRule.between(addMonths(new Date(), -1), addMonths(new Date(), 12));
  }

  getRangeEventInputs(calenderDateRanges: CalenderDateRange[]): EventInput[] {
    const normalDatesArray: EventInput[] = [];
    const rRuleDatesArray: EventInput[] = [];

    for (const x of calenderDateRanges) {
      const tempDateArray: Date[] = [];
      if (x.rRule === null || x.rRule === '' || x.rRule === undefined) {
        normalDatesArray.push({
          id: x.id.toString(),
          title: x.description,
          color: x.color,
          textColor: '#383838',
          editable: false,
          start: format(new Date(x.from), 'yyyy-MM-dd'),
          end: format(addDays(new Date(x.to), 1), 'yyyy-MM-dd'),
          allDay: true,
        });
      } else {
        this.getRRDates(x.from.toString(), x.rRule).forEach((v) =>
          tempDateArray.push(v)
        );
        // tempDateArray.concat(this.getRRDates(x.from.toString(), x.rRule));
        // console.log(this.getRRDates(x.from.toString(), x.rRule));
        for (const item of tempDateArray) {
          rRuleDatesArray.push({
            id: x.id.toString(),
            title: x.description + ' 🔄',
            color: x.color,
            textColor: '#383838',
            editable: false,
            start: format(new Date(item), 'yyyy-MM-dd'),
            end: format(addDays(new Date(item), 1), 'yyyy-MM-dd'),
            allDay: true,
          });
        }
      }
    }
    // console.log(normalDatesArray);

    return normalDatesArray.concat(rRuleDatesArray);
  }

  internDateStringFormatter(date: Date): string {
    console.log(date.getMinutes());
    console.log(date.getHours());
    console.log(date.getDate());
    console.log(date.getDay());
    console.log(date.getMonth());
    let newTime = date;
    if (date.getHours() < 11 && date.getMinutes() <= 45) {
      newTime = subDays(date, 1);
      return this.dateStringLineFormatter(
        newTime.getMonth(),
        this.getDayByWeekday(newTime.getDay(), newTime.getDate()),
        this.getWorkWeekday(newTime.getDay()),
        '13'
      );
    } else if (date.getHours() < 13 && date.getMinutes() <= 45) {
      newTime = subDays(date, 1);
      return this.dateStringLineFormatter(
        newTime.getMonth(),
        this.getDayByWeekday(newTime.getDay(), newTime.getDate()),
        this.getWorkWeekday(newTime.getDay()),
        '16'
      );
    } else if (date.getHours() < 17 && date.getMinutes() <= 45) {
      return this.dateStringLineFormatter(
        newTime.getMonth(),
        newTime.getDate(),
        newTime.getDay(),
        '09'
      );
    } else {
      return this.dateStringLineFormatter(
        newTime.getMonth(),
        newTime.getDate(),
        newTime.getDay(),
        '13'
      );
    }
  }

  getWorkWeekday(weekday: number): number {
    if (+weekday === 0 || +weekday === 6) {
      return 5;
    } else {
      return +weekday;
    }
  }

  getDayByWeekday(weekday: number, day: number): number {
    if (+weekday === 0) {
      return +day - 2;
    }
    if (+weekday === 6) {
      return +day - 1;
    } else {
      return +day;
    }
  }

  dateStringLineFormatter(
    month: number,
    day: number,
    weekday: number,
    hour: string
  ): string {
    const monthList = [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ];
    const dayList = [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ];
    return `${dayList[weekday]} den ${day}. ${monthList[month]} ${hour}:00 Uhr`;
  }
}
