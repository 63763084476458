<div class="clr-row clr-justify-content-between">
    <div class="clr-col-6 clr-align-self-center">
        <h3 class="pa-5">
            Labor Standorte
        </h3>
        <button (click)="addModal = !addModal" class="btn btn-sm">Neuen Labor Standort
            hinzufügen</button>
        <clr-datagrid>
            <clr-dg-column>Labor Standort</clr-dg-column>
            <clr-dg-column>Techniker</clr-dg-column>

            <clr-dg-row (click)="openPatchModal(lab)" *ngFor="let lab of labs">
                <clr-dg-cell>{{lab.labName}}</clr-dg-cell>
                <clr-dg-cell *ngIf="lab.technicians.length === 0" >keine Techniker zugewiesen</clr-dg-cell>
                <clr-dg-cell *ngIf="lab.technicians.length > 0" >{{ lab.technicians.length }} Techniker</clr-dg-cell>
            </clr-dg-row>

            <clr-dg-footer>{{labs.length}} Labore</clr-dg-footer>
        </clr-datagrid>
    </div>

    <clr-modal [(clrModalOpen)]="addModal" [clrModalClosable]="false">
        <h3 class="modal-title">Neuen Labor-Standort hinzufügen</h3>
        <div class="modal-body">
            <clr-input-container>
                <label>Name</label>
                <input clrInput name="name" #name />
            </clr-input-container>
        </div>
        <div class="modal-footer">
            <button (click)="addModal = !addModal" class="btn btn-outline" type="button">Cancel</button>
            <button
                (click)="createLab(name.value)"
                class="btn btn-primary" type="button">Ok</button>
        </div>
    </clr-modal>

    <div *ngIf="detailData">
        <clr-modal [(clrModalOpen)]="patchModal" [clrModalClosable]="false">
            <h3 class="modal-title">{{ detailData.labName }} bearbeiten</h3>
            <div class="modal-body">
                <clr-input-container>
                    <label>Standort</label>
                    <input clrInput name="name" value="{{ detailData.labName }}" [(ngModel)]="detailData.labName" />
                </clr-input-container>
            </div>
            <div class="modal-footer">
                <button (click)="patchModal = !patchModal" class="btn btn-outline" type="button">Cancel</button>
                <button (click)="updateLab()" class="btn btn-primary" type="button">Ok</button>
            </div>
        </clr-modal>
    </div>