import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import {
  addWeeks,
  Calendar,
  CalendarOptions,
  DateSelectArg,
  EventInput,
  EventSourceInput,
  FullCalendarComponent,
} from '@fullcalendar/angular';
import { CalenderEntry } from 'src/app/models/calender-entry.interface';
import { CalenderStatus } from 'src/app/models/calender-status.interface';
import { CalenderDateRange } from 'src/app/models/calenderDate.interface';
import { Task } from 'src/app/models/task.interface';
import { TaskDate } from 'src/app/models/taskDate.interface';
import { Technician } from 'src/app/models/technician.interface';
import { DataService } from 'src/app/services/data.service';
import { DateService } from 'src/app/services/date.service';
import { HelperService } from 'src/app/services/helper.service';
import { RestService } from 'src/app/services/rest.service';
import tippy from 'tippy.js';
import { createPopper } from '@popperjs/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { TaskLocationStatus } from 'src/app/models/task-status.enum';
import { RRule, RRuleSet, rrulestr } from 'rrule';
import { HoverType } from 'src/app/models/hover-enum';
import {
  AllowIn,
  KeyboardShortcutsComponent,
  ShortcutEventOutput,
  ShortcutInput,
} from 'ng-keyboard-shortcuts';
import { GeneralDate } from 'src/app/models/general-date.interface';

@Component({
  selector: 'app-calender-content',
  templateUrl: './calender-content.component.html',
  styleUrls: ['./calender-content.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(350, style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class CalenderContentComponent implements OnInit {
  showAllDays = true;
  taskDetail: Task;
  smallTaskDetail: Task;
  taskDateDetail: TaskDate = {
    id: 0,
    date: new Date(),
    taskId: 0,
    labOwnerId: 0,
    calenderStatus: null,
    calenderStatusId: 0,
  };

  taskDateRangeDetail: CalenderDateRange = {
    id: 0,
    from: new Date(),
    to: new Date(),
    rRule: null,
    notes: null,
    color: '#ffff',
    description: '',
  };

  generalDateDetailPage: GeneralDate;
  generalDateDetailPageModal = false;

  generalDateDetail: GeneralDate = {
    id: 0,
    date: new Date(),
    dateDescription: '',
    calenderColor: '#ffff',
    description: '',
    labOwnerId: 0,
    calenderStatus: null,
    calenderStatusId: 0,
  };

  @ViewChild('calendar', { static: true }) calendar: FullCalendarComponent;

  detailModal = false;
  taskDetailModal = false;
  addModal = false;
  hover = false;
  calendarOptions: CalendarOptions;

  dates: TaskDate[];
  technicians: Technician[] = [];
  calenderEntry: EventInput[] = [];
  tasks: Task[] = [];
  calenderStatus: CalenderStatus[] = [];
  calenderDateRange: CalenderDateRange[] = [];
  generalDates: GeneralDate[] = [];
  taskSelection: any;
  selectedDate: any;
  hours = '08';
  minutes = '00';
  editMode = false;
  calenderApi: Calendar;
  today: Date = new Date();
  dateSelectArg: DateSelectArg;
  generalDateModal = false;
  hoverType: HoverType = HoverType.TASK;
  calendarReady = false;
  eventCount = 0;
  shortcuts: ShortcutInput[] = [
    {
      key: 'ctrl + t',
      preventDefault: true,
      command: (e) => console.log('clicked ', e.key),
    },
    {
      key: ['? a'],
      label: 'Sequences',
      description: 'Sequence ? and a',
      command: (output: ShortcutEventOutput) => console.log('? a', output),
      preventDefault: true,
    },
  ];

  @ViewChild(KeyboardShortcutsComponent)
  private keyboard: KeyboardShortcutsComponent;

  button = document.querySelector('#button');

  tooltip = document.querySelector('#tooltip');
  viewDateStart: Date = addWeeks(new Date(), -1);
  viewDateEnd: Date = addWeeks(new Date(), 2);

  constructor(
    private data: DataService,
    public dateService: DateService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {
    this.data.refreshTaskDates().then();
    this.data.refreshTechnicians().then();
    this.data.refreshTasks().then();
    this.data.refreshCalenderStatus().then();
    this.data.refreshGeneralDates().then();
    this.data.currentTasks.subscribe((task) => (this.tasks = task));
    this.data.currentCalenderStatus.subscribe((s) => (this.calenderStatus = s));
    this.data.currentTechnicians.subscribe((t) => (this.technicians = t));
    this.data.currentGeneralDate.subscribe((g) => (this.generalDates = g));
    this.data.refreshCalenderDateRange().then();
    this.data.currentCalenderDateRanges.subscribe(
      (c) => (this.calenderDateRange = c)
    );
    this.data.currentTaskDate.subscribe((v) => {
      this.dates = v;
      this.setCalender(v);
    });
    this.keyboard.select('cmd + f').subscribe((e) => console.log(e));
  }

  getDaysBetween(from: Date, to: Date): number {
    return this.dateService.getDaysBetween(from, to);
  }
  /*   getWeekDates(): void {
    this.data.getCalenderWeek(this.viewDateStart, this.viewDateEnd);
  } */

  setRangeDates(): void {
    this.calenderApi = this.calendar.getApi();
    this.viewDateStart = this.calenderApi.view.activeStart;
    this.viewDateEnd = this.calenderApi.view.activeEnd;
    this.data.setWeek(this.viewDateStart, this.viewDateEnd);
    const events = this.calenderApi
      .getEvents()
      .filter((v) => v.allDay === false);
    const eventCountViewStart = events.filter(
      (v) => v.start >= this.calenderApi.view.activeStart
    );
    this.eventCount = eventCountViewStart.filter(
      (v) => v.start <= this.calenderApi.view.activeEnd
    ).length;
  }

  rRuleStringToText(rRuleString: string): string {
    if (!rRuleString) {
      return 'keine Regel';
    }
    const options = this.helper.rRuleOptionsBuilder(rRuleString);
    return this.helper.rRuleOptionsToText(options);
  }

  goToDate(date: Date): void {
    //  this.data.getCalenderWeek(date, null);
    this.calendar.getApi().gotoDate(date);
  }

  dateSelect(e: Date): void {
    this.goToDate(e);
  }

  checkRefresh(e: Date): void {
    if (e.getTime() === this.viewDateStart.getTime()) {
    } else {
      //  this.getWeekDates();
    }
  }

  async updateTaskLocationStatus(): Promise<void> {
    const newTask = this.taskDetail;
    if (newTask.locationStatus === TaskLocationStatus.INHOUSE) {
      newTask.locationStatus = TaskLocationStatus.NOTINHOUSE;
    } else {
      newTask.locationStatus = TaskLocationStatus.INHOUSE;
    }
    await this.data.updateTask(newTask);
  }

  // tslint:disable-next-line: typedef

  setCalender(taskDates: TaskDate[]): void {
    this.dates = taskDates;
    const entry: EventInput[] = [];
    this.dates.forEach((e) => {
      const singleEntry: EventInput = {
        id: e.id.toString(),
        title: this.helper.descriptionSplitter(e.calenderDescription),
        color: e.calenderColor,
        textColor: e.calenderFontColor,
        editable: this.editMode,
        start: e.date.toString(),
        end: new Date(new Date(e.date).getTime() + 15 * 60 * 1000),
      };
      entry.push(singleEntry);
    });
    this.generalDates.forEach((g) => {
      const singleEntry: EventInput = {
        id: '+' + g.id.toString(),
        title: g.description,
        color: g.calenderColor,
        textColor: g.calenderFontColor,
        editable: this.editMode,
        start: g.date.toString(),
        end: new Date(new Date(g.date).getTime() + 15 * 60 * 1000),
      };
      entry.push(singleEntry);
    });
    this.dateService
      .getRangeEventInputs(this.calenderDateRange)
      .forEach((v) => entry.push(v));
    // entry.concat(this.dateService.getRangeEventInputs(this.calenderDateRange));
    /*     this.calenderDateRange.forEach((x) => {
      const singleCalenderDateRange: EventInput = {
        id: x.id.toString(),
        title: x.description,
        color: x.color,
        textColor: '#383838',
        editable: false,
        start: this.dateService.reformatDateStringForCalender(x.from, false),
        end: this.dateService.reformatDateStringForCalender(x.to, true),
      };
      entry.concat(th);
    }); */
    this.calenderEntry = entry;

    // this.calenderEntry.push(testData);
    // this.calenderEntry.push(testData2);
    this.calendarOptions = {
      allDaySlot: this.showAllDays,
      customButtons: {
        myCustomButton: {
          text: 'heute',
          click: (e) => {
            this.goToDate(new Date());
          },
        },
      },
      initialView: 'timeGridFourDay',
      firstDay: 1,
      selectable: true,
      select: (e) => {
        this.dateSelectArg = e;
      },
      slotDuration: '00:15:00',
      buttonText: {
        week: 'Woche',
        day: 'Tag',
        today: 'heute',
        timeGridFourDay: '5 Tage',
      },
      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'timeGridDay,timeGridFourDay,timeGridWeek',
      },
      views: {
        timeGridFourDay: {
          hiddenDays: [0, 6],
          type: 'timeGridWeek',
        },
      },
      /*       eventDidMount: (e) => {
        e.el.setAttribute('popper', 'popper1Content');
        e.el.setAttribute('popperShowOnStart', 'true');
        e.el.setAttribute('popperTrigger', 'hover');
        e.el.setAttribute('popperHideOnClickOutside', 'true');
        e.el.setAttribute('popperHideOnScroll', 'true');
        e.el.setAttribute('popperPlacement', 'right');
      }, */
      height: window.innerHeight,
      displayEventTime: false,
      expandRows: true,
      allDayText: 'Ganztägig',
      slotMinTime: '07:00',
      slotEventOverlap: false,
      slotMaxTime: '19:30',
      nowIndicator: true,
      locale: 'de',
      themeSystem: 'standard',
      snapDuration: '00:15:00',
      eventDidMount: (e) => {
        this.checkRefresh(e.view.activeStart);
      },
    allDayDidMount: () => {
        this.setRangeDates();
    },
/*      viewDidMount: (e) => {
        this.setRangeDates();
      },*/

      eventMouseLeave: () => {
        this.hover = false;
        this.smallTaskDetail = null;
        this.generalDateDetail = null;
        this.taskDateRangeDetail = null;
      },
      // eventLeave: () => {this.smallTaskDetail = null; },
      eventMouseEnter: (e) => {
        if (e.event.allDay) {
          console.log('ALLDAY');
          this.hoverType = HoverType.ALLDAY;
          this.smallDetailTaskAllDayDate(+e.event.id);
        } else {
          if (e.event.id.startsWith('+')) {
            console.log('GENERAL');
            this.hoverType = HoverType.GENERAL;
            this.smallDetailGeneralDate(+e.event.id.replace('+', ''));
          } else {
            console.log('TASK');
            this.hoverType = HoverType.TASK;
            this.smallDetailTaskDate(+e.event.id);
          }
        }
        //  console.log(e);
        this.hover = true;
        createPopper(e.el, document.querySelector('#tooltip'), {
          placement: 'bottom-start',
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top', 'right'],
              },
            },
          ],
          /*         modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-50, -150],
              },
            },
          ], */
        });
      },
      // dateClick: this.handleDateClick.bind(this), // bind is important!
      eventClick: this.handleEventClick.bind(this),
      eventDrop: this.handleEventDrag.bind(this),
      // eventLongPressDelay: (e) => {console.log(e)},
      events: this.calenderEntry,
      slotLabelInterval: {
        second: 1,
      },
    };
  }

  async showAllDaySlot(): Promise<void> {
    this.showAllDays = !this.showAllDays;
    this.setCalender(this.dates);
  }

  async createGeneralDate(
    description: string,
    statusId: number
  ): Promise<void> {
    const date = this.dateSelectArg.start;
    const result = await this.data.createGeneralDate(
      date,
      description,
      statusId
    );
    this.generalDateModal = false;
    this.dateSelectArg = null;
    return result;
  }

  getDateString(date: string): string {
    const newDate = new Date(date);
    const minutes =
      newDate.getMinutes() === 0 ? '00' : newDate.getMinutes().toString();
    return `${newDate.getHours()}:${minutes}`;
  }

  getCalenderStatusString(id: number): string {
    const found = this.calenderStatus.find((v) => v.id === id);
    return found.statusDescription;
  }

  async deleteDate(date: TaskDate): Promise<void> {
    await this.data.deleteTaskDate(date.id);
    this.taskDetailModal = false;
    this.taskDateDetail = null;
  }

  getTimeString(): string {
    return this.dateService.getTimeString(this.hours, this.minutes);
  }

  getCalenderStatusById(id: number): CalenderStatus {
    return this.calenderStatus.find((v) => v.id === +id);
  }

  async updateTaskDate(taskDateId: number, newDate: Date): Promise<void> {
    const taskDate: TaskDate = this.dates.find((e) => e.id === +taskDateId);
    taskDate.date = newDate;
    await this.data.updateTaskDate(taskDate);
  }

  async updateGeneralDate(taskDateId: number, newDate: Date): Promise<void> {
    const generalDate: GeneralDate = this.generalDates.find(
      (e) => e.id === +taskDateId
    );
    generalDate.date = newDate;
    await this.data.updateGeneralDate(generalDate);
  }

  async deleteGeneralDate(): Promise<void> {
    await this.data.deleteGeneralDate(this.generalDateDetailPage);
    this.generalDateDetailPageModal = false;
    this.generalDateDetailPage = null;
  }

  async detailTaskDate(taskDateId: number): Promise<void> {
    this.taskDetail = await this.data.getSingleTaskById(
      this.taskDateDetail.taskId
    );
    this.taskDateDetail = this.dates.find((e) => e.id === taskDateId);
    if (this.editMode) {
      this.taskDetailModal = true;
    } else {
      this.detailModal = true;
    }
  }

  async smallDetailTaskDate(taskDateId: number): Promise<void> {
    this.taskDateDetail = this.dates.find((e) => e.id === taskDateId);
    this.smallTaskDetail = await this.data.getSingleTaskById(
      this.taskDateDetail.taskId
    );
  }

  smallDetailGeneralDate(hoverId: number): void {
    const result = this.generalDates.find((e) => e.id === hoverId);
    this.generalDateDetail = result;
  }

  detailGeneralDate(hoverId: number): void {
    const result = this.generalDates.find((e) => e.id === hoverId);
    this.generalDateDetailPage = result;
  }

  async smallDetailTaskAllDayDate(hoverId: number): Promise<void> {
    this.taskDateRangeDetail = await this.data.getSingleCalenderDate(hoverId);
  }

  createTaskDate(calenderStatusId: number): void {
    const date = this.dateService.dateFromHourMinutesDate(
      +this.hours,
      +this.minutes,
      this.selectedDate
    );
    this.data.createTaskDate(date, this.taskSelection.id, calenderStatusId);
    this.addModal = false;
  }

  async updateTaskDateStatus(): Promise<void> {
    await this.data.updateTaskDate(this.taskDateDetail);
    this.taskDetailModal = false;
  }

  activateEditMode(): void {
    this.editMode = true;
    this.setCalender(this.dates);
  }

  deActivateEditMode(): void {
    this.editMode = false;
    this.setCalender(this.dates);
  }

  handleDateClick(arg: any): void {
    alert('date click! ' + arg);
  }

  handleLongPressEventClick(arg: any): void {
    alert('longpress click! ' + JSON.stringify(arg));
  }

  handleEventClick(info: any): void {
    if (info.event.id.startsWith('+')) {
      this.detailGeneralDate(+info.event.id.replace('+', ''));
      this.generalDateDetailPageModal = true;
    } else {
      this.detailTaskDate(+info.event.id);
    }
  }

  async handleEventDrag(info: any): Promise<void> {
    if (info.event.id.startsWith('+')) {
      await this.updateGeneralDate(
        +info.event.id.replace('+', ''),
        info.event.start.toISOString()
      );
    } else {
      await this.updateTaskDate(+info.event.id, info.event.start.toISOString());
    }
  }

  checkDate(date: Date): boolean {
    const current = new Date();
    const parse = new Date(date);
    if (current > parse) {
      return true;
    } else {
      return false;
    }
  }

  getTechnician(id: number): Technician {
    const found = this.technicians.find((v) => v.id === +id);
    if (found) {
      return found;
    } else {
      return null;
    }
  }
}
