import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BatchProduct, ProductType } from '../models/batch-product.interface';
import { BatchScan } from '../models/batch-scan.interface';
import { Batch } from '../models/batch.interface';
import { CalenderStatus } from '../models/calender-status.interface';
import { CalenderDateRange } from '../models/calenderDate.interface';
import { Dentist } from '../models/dentist.interface';
import { GeneralDate } from '../models/general-date.interface';
import { Lab } from '../models/lab.interface';
import { TaskStatus } from '../models/task-status.enum';
import { Task } from '../models/task.interface';
import { TaskDate } from '../models/taskDate.interface';
import { Technician } from '../models/technician.interface';
import { User } from '../models/user.interface';
import { RestService } from './rest.service';
import {addWeeks, endOfWeek, startOfWeek, subWeeks} from 'date-fns';
import {de} from 'date-fns/locale';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  technicians: Technician[] = [];
  weekStart: Date = startOfWeek(new Date(), {locale: de, weekStartsOn: 1});
  weekEnd: Date = endOfWeek(new Date(), {locale: de, weekStartsOn: 1});

  private technicianSource = new BehaviorSubject<Technician[]>(
    this.technicians
  );
  currentTechnicians: Observable<
    Technician[]
  > = this.technicianSource.asObservable();

  labs: Lab[] = [];

  private labsSource = new BehaviorSubject<Lab[]>(this.labs);
  currentLabs: Observable<Lab[]> = this.labsSource.asObservable();

  dentists: Dentist[] = [];

  private dentistsSource = new BehaviorSubject<Dentist[]>(this.dentists);
  currentDentists: Observable<Dentist[]> = this.dentistsSource.asObservable();

  taskDates: TaskDate[] = [];

  private taskDateSource = new BehaviorSubject<TaskDate[]>(this.taskDates);
  currentTaskDate: Observable<TaskDate[]> = this.taskDateSource.asObservable();

  generalDates: GeneralDate[] = [];

  private generalDateSource = new BehaviorSubject<GeneralDate[]>(
    this.generalDates
  );
  currentGeneralDate: Observable<
    GeneralDate[]
  > = this.generalDateSource.asObservable();

  calenderStatus: CalenderStatus[] = [];

  private calenderStatusDateSource = new BehaviorSubject<CalenderStatus[]>(
    this.calenderStatus
  );
  currentCalenderStatus: Observable<
    CalenderStatus[]
  > = this.calenderStatusDateSource.asObservable();

  tasks: Task[] = [];

  private taskSource = new BehaviorSubject<Task[]>(this.tasks);
  currentTasks: Observable<Task[]> = this.taskSource.asObservable();

  calenderDateRanges: CalenderDateRange[] = [];

  private calenderDateRangeSource = new BehaviorSubject<CalenderDateRange[]>(
    this.calenderDateRanges
  );
  currentCalenderDateRanges: Observable<
    CalenderDateRange[]
  > = this.calenderDateRangeSource.asObservable();

  batchProducts: BatchProduct[] = [];

  private batchProductsSource = new BehaviorSubject<BatchProduct[]>(
    this.batchProducts
  );
  currentBatchProducts: Observable<
    BatchProduct[]
  > = this.batchProductsSource.asObservable();

  batchScans: BatchScan[] = [];

  private batchScansSource = new BehaviorSubject<BatchScan[]>(this.batchScans);
  currentBatchScans: Observable<
    BatchScan[]
  > = this.batchScansSource.asObservable();

  batches: Batch[] = [];

  private batchesSource = new BehaviorSubject<Batch[]>(this.batches);
  currentBatches: Observable<Batch[]> = this.batchesSource.asObservable();

  user: User = {
    id: 1,
    company: 'string',
    email: 'string',
    firstName: 'string',
    lastName: 'string',
  };

  private userSource = new BehaviorSubject<User>(this.user);
  currentUser: Observable<User> = this.userSource.asObservable();

  constructor(private restService: RestService) {}

  async getSingleTaskById(taskId: number): Promise<Task> {
    return await this.restService.getTasksById(taskId);
  }

  async refreshCalenderStatus(): Promise<void> {
    const calenderStatus = await this.restService.getCalenderStatus();
    this.calenderStatus = calenderStatus;
    this.calenderStatusDateSource.next(calenderStatus);
    await this.refreshTaskDates();
  }

  async updateCalenderStatus(calenderStatus: CalenderStatus): Promise<void> {
    await this.restService.updateCalenderStatus(calenderStatus);
    await this.refreshCalenderStatus();
  }

  async createCalenderStatus(
    statusDescription: string,
    color: string,
    fontcolor: string
  ): Promise<void> {
    await this.restService.createCalenderStatus(
      statusDescription,
      color,
      fontcolor
    );
    await this.refreshCalenderStatus();
  }

  async refreshUser(): Promise<void> {
    const user = await this.restService.getUserData();
    this.user = user;
    this.userSource.next(user);
  }

  async refreshGeneralDates(): Promise<void> {
    const generalDates = await this.restService.getGeneralDates();
    this.generalDates = generalDates;
    this.generalDateSource.next(generalDates);
  }

  async updateGeneralDate(generalDate: GeneralDate): Promise<void> {
    await this.restService.updateGeneralDate(generalDate);
    console.log('update general date');
    await this.refreshGeneralDates();
  }

  async deleteGeneralDate(generalDate: GeneralDate): Promise<void> {
    await this.restService.deleteGeneralDate(generalDate);
    await this.refreshGeneralDates();
  }

  async createGeneralDate(
    date: Date,
    description: string,
    calenderStatusId: number
  ): Promise<void> {
    await this.restService.createGeneralDate(
      date,
      description,
      calenderStatusId
    );
    await this.refreshGeneralDates();
  }

  setWeek(start: Date, end: Date): void {
    this.weekStart = subWeeks(start, 1);
    this.weekEnd = addWeeks(end, 1 );
    this.refreshTaskDates().then();
  }

  async refreshTaskDates(): Promise<void> {
    const taskDates = await this.restService.getTaskDates(this.weekStart, this.weekEnd);
    this.taskDates = taskDates;
    this.taskDateSource.next(taskDates);
  }

  async updateTaskDate(taskDate: TaskDate): Promise<void> {
    await this.restService.updateTaskDate(taskDate);
    await this.refreshTaskDates();
  }

  async createTaskDate(
    date: Date,
    taskId: number,
    calenderStatusId: number
  ): Promise<void> {
    await this.restService.createTaskDate(date, taskId, calenderStatusId);
    await this.refreshTaskDates();
  }

  async refreshTasks(): Promise<void> {
    const tasks = await this.restService.getTasksByStatus(TaskStatus.ACTIVE);
    this.tasks = tasks;
    this.taskSource.next(tasks);
  }

  async updateTask(task: Task): Promise<void> {
    await this.restService.updateTask(task);
    await this.refreshTasks();
  }

  async createTask(
    uid: string,
    description: string,
    patient: string,
    dentistId: number,
    taskOwnerId: number
  ): Promise<Task> {
    const task = await this.restService.createTask(
      uid,
      description,
      patient,
      dentistId,
      taskOwnerId
    );
    await this.refreshTasks();
    return task;
  }

  async refreshTechnicians(): Promise<void> {
    const technicians = await this.restService.getTechnicians2();
    this.technicians = technicians;
    await this.refreshLabs();
    this.technicianSource.next(technicians);
  }

  async updateTechnician(technician: Technician): Promise<void> {
    await this.restService.updateTechnicians2(technician);
    await this.refreshTechnicians();
  }

  async createTechnician(
    technicianId: string,
    firstName: string,
    profileImageUrl: string,
    lastName: string,
    labId: number
  ): Promise<void> {
    await this.restService.createTechnicians2(
      technicianId,
      firstName,
      profileImageUrl,
      lastName,
      labId
    );
    await this.refreshTechnicians();
  }

  async refreshDentists(): Promise<void> {
    const dentists = await this.restService.getDentists2();
    this.dentists = dentists;
    this.dentistsSource.next(dentists);
  }

  async createDentist(name: string, location: string): Promise<void> {
    await this.restService.createDentist2(name, location);
    await this.refreshDentists();
  }

  async updateDentist(dentist: Dentist): Promise<void> {
    await this.restService.updateDentist2(dentist);
    await this.refreshDentists();
  }

  async refreshLabs(): Promise<void> {
    const labs = await this.restService.getLabs2();
    const labList: Lab[] = [];
    labs.forEach((v) => {
      v.technicians = this.technicians.filter((f) => f.labId === v.id);
      labList.push(v);
    });
    this.labs = labList;
    this.labsSource.next(labList);
  }

  async updateLab(lab: Lab): Promise<void> {
    await this.restService.updateLab2(lab);
    await this.refreshLabs();
  }

  async createLab(labName: string): Promise<void> {
    await this.restService.createLab2(labName);
    await this.refreshLabs();
  }

  async getSingleCalenderDate(id: number): Promise<CalenderDateRange> {
    return await this.restService.getSingleCalenderDate(id);
  }

  async refreshCalenderDateRange(): Promise<void> {
    const calenderDateRange = await this.restService.getCalenderDates();
    this.calenderDateRanges = calenderDateRange;
    this.calenderDateRangeSource.next(calenderDateRange);
  }

  async createCalenderDateRange(
    from: string,
    to: string,
    color: string,
    description: string,
    rRule: string,
    notes: string
  ): Promise<void> {
    await this.restService.createCalenderDate(
      from,
      to,
      color,
      notes,
      description,
      rRule
    );
    await this.refreshCalenderDateRange();
  }

  async updateCalenderDateRange(
    calenderDateRange: CalenderDateRange
  ): Promise<void> {
    await this.restService.updateCalenderDate(calenderDateRange);
    await this.refreshCalenderDateRange();
  }

  async deleteCalenderDateRange(id: number): Promise<void> {
    await this.restService.deleteCalenderDate(id);
    await this.refreshCalenderDateRange();
  }

  async deleteTaskDate(id: number): Promise<void> {
    await this.restService.deleteTaskDate(id);
    await this.refreshTaskDates();
  }

  async deleteCalenderStatus(id: number): Promise<void> {
    await this.restService.deleteCalenderStatus(id);
    await this.refreshCalenderStatus();
  }

  // BATCH
  async refreshBatch(): Promise<void> {
    const batches = await this.restService.getBatches();
    this.batches = batches;
    this.batchesSource.next(batches);
  }

  async createBatch(
    uid: string,
    lot: string,
    bestBefore: Date,
    productId: number
  ): Promise<Batch> {
    const batch = await this.restService.createBatch(
      uid,
      lot,
      bestBefore,
      productId
    );
    await this.refreshBatch();
    return batch;
  }

  async updateBatch(batch: Batch): Promise<void> {
    await this.restService.updateBatch(batch);
    await this.refreshBatch();
  }

  async refreshBatchProduct(): Promise<void> {
    const batchProducts = await this.restService.getBatchProducts();
    this.batchProducts = batchProducts;
    this.batchProductsSource.next(batchProducts);
  }

  async createBatchProduct(
    productName: string,
    productType: ProductType
  ): Promise<void> {
    await this.restService.createBatchProduct(productName, productType);
    await this.refreshBatchProduct();
  }

  async updateBatchProduct(batchProduct: BatchProduct): Promise<void> {
    await this.restService.updateBatchProduct(batchProduct);
    await this.refreshBatchProduct();
  }

  async refreshBatchScan(): Promise<void> {
    const batchScans = await this.restService.getBatchScans();
    this.batchScans = batchScans;
    this.batchScansSource.next(batchScans);
  }

  async createBatchScan(taskId: number, batchId: number): Promise<void> {
    await this.restService.createBatchScan(taskId, batchId);
    await this.refreshBatchScan();
  }

  async updateBatchScan(batchScan: BatchScan): Promise<void> {
    await this.restService.updateBatchScan(batchScan);
    await this.refreshBatchScan();
  }

  async getBatchScansByTaskId(id: number): Promise<BatchScan[]> {
    return this.restService.getBatchScansByTaskId(id);
  }
}
