import { Injectable } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { WebSocketResponse } from '../models/websocket-res.interface';
import { CRUD, UpdateCategory } from '../models/ws.category.enum';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class WsNotificationService {

  constructor(
    private data: DataService,
    private notifications: NotificationsService,
  ) { }

  temp: any = {
    type: 'info',
    title: '',
    content: '',
    timeOut: 2000,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    animate: 'fromRight'
  };

  async refreshDataAndSendNotification(webSocketResponse: WebSocketResponse): Promise<void> {
    let categoryTitle = '';
    let operationTitle = '';
    let title = '';
    let notificationType: NotificationType = NotificationType.Info;
    switch (webSocketResponse.category) {
      // BATCHES
      case UpdateCategory.BATCHES:
        categoryTitle = 'Chargen';
        switch (webSocketResponse.operation) {
          case CRUD.CREATE:
            operationTitle = 'erstellt';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Success;
            break;
          case CRUD.UPDATE:
            operationTitle = 'geändert';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Info;
            break;
          case CRUD.DELETE:
            operationTitle = 'gelöscht';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Error;
            break;
          default:
            break;
        }
        // this.data.refreshTaskDates();
        this.notifications.create(title, 'Anwendung wurde aktualisiert', notificationType, this.temp);
        break;

      // BATCHPRODUCTS
      case UpdateCategory.BATCHPRODUCTS:
        categoryTitle = 'Produkte';
        switch (webSocketResponse.operation) {
          case CRUD.CREATE:
            operationTitle = 'erstellt';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Success;
            break;
          case CRUD.UPDATE:
            operationTitle = 'geändert';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Info;
            break;
          case CRUD.DELETE:
            operationTitle = 'gelöscht';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Error;
            break;
          default:
            break;
        }
        // this.data.refreshTaskDates();
        this.notifications.create(title, 'Anwendung wurde aktualisiert', notificationType, this.temp);
        break;
      // CALENDERSTATUS
      case UpdateCategory.CALENDERSTATUS:
        categoryTitle = 'Kalender Status';
        switch (webSocketResponse.operation) {
          case CRUD.CREATE:
            operationTitle = 'erstellt';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Success;
            break;
          case CRUD.UPDATE:
            operationTitle = 'geändert';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Info;
            break;
          case CRUD.DELETE:
            operationTitle = 'gelöscht';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Error;
            break;
          default:
            break;
        }
        await this.data.refreshCalenderStatus();
        this.notifications.create(title, 'Anwendung wurde aktualisiert', notificationType, this.temp);
        break;
      // DATES
      case UpdateCategory.DATES:
        categoryTitle = 'Termin';
        switch (webSocketResponse.operation) {
          case CRUD.CREATE:
            operationTitle = 'erstellt';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Success;
            break;
          case CRUD.UPDATE:
            operationTitle = 'geändert';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Info;
            break;
          case CRUD.DELETE:
            operationTitle = 'gelöscht';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Error;
            break;
          default:
            break;
        }
        await this.data.refreshTaskDates();
        this.notifications.create(title, 'Anwendung wurde aktualisiert', notificationType, this.temp);
        break;
      // DENTISTS
      case UpdateCategory.DENTISTS:
        categoryTitle = 'Zahnarzt';
        switch (webSocketResponse.operation) {
          case CRUD.CREATE:
            operationTitle = 'erstellt';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Success;
            break;
          case CRUD.UPDATE:
            operationTitle = 'geändert';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Info;
            break;
          case CRUD.DELETE:
            operationTitle = 'gelöscht';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Error;
            break;
          default:
            break;
        }
        await this.data.refreshDentists();
        this.notifications.create(title, 'Anwendung wurde aktualisiert', notificationType, this.temp);
        break;
      // LABS
      case UpdateCategory.LABS:
        categoryTitle = 'Labor';
        switch (webSocketResponse.operation) {
          case CRUD.CREATE:
            operationTitle = 'erstellt';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Success;
            break;
          case CRUD.UPDATE:
            operationTitle = 'geändert';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Info;
            break;
          case CRUD.DELETE:
            operationTitle = 'gelöscht';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Error;
            break;
          default:
            break;
        }
        await this.data.refreshLabs();
        this.notifications.create(title, 'Anwendung wurde aktualisiert', notificationType, this.temp);
        break;
      // TASKS
      case UpdateCategory.TASKS:
        categoryTitle = 'Aufgabe';
        switch (webSocketResponse.operation) {
          case CRUD.CREATE:
            operationTitle = 'erstellt';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Success;
            break;
          case CRUD.UPDATE:
            operationTitle = 'geändert';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Info;
            break;
          case CRUD.DELETE:
            operationTitle = 'gelöscht';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Error;
            break;
          default:
            break;
        }
        await this.data.refreshTasks();
        this.notifications.create(title, 'Anwendung wurde aktualisiert', notificationType, this.temp);
        break;
      // TECHNICIAN
      case UpdateCategory.TECHNICIAN:
        categoryTitle = 'Techniker';
        switch (webSocketResponse.operation) {
          case CRUD.CREATE:
            operationTitle = 'erstellt';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Success;
            break;
          case CRUD.UPDATE:
            operationTitle = 'geändert';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Info;
            break;
          case CRUD.DELETE:
            operationTitle = 'gelöscht';
            title = `${categoryTitle} ${operationTitle}!`;
            notificationType = NotificationType.Error;
            break;
          default:
            break;
        }
        await this.data.refreshTechnicians();
        this.notifications.create(title, 'Anwendung wurde aktualisiert', notificationType, this.temp);
        break;

      default:
        break;
    }
  }
}
