<div class="main-container">
    <header class="header-4">
        <div class="branding">
            <a href="javascript://" class="nav-link">
                <clr-icon style="color: whitesmoke;" shape="home"></clr-icon>
                <span class="title">Dental-Lab Manager</span>
            </a>
        </div>
        <div class="header-actions">
            <clr-dropdown>
                <a href="javascript://" clrDropdownTrigger aria-label="toggle settings menu"
                    class="nav-link nav-icon-text">
                    <clr-icon shape="user"></clr-icon>
                    <span class="nav-text">{{ labOwner.company }}</span>
                </a>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
             

                    <a clrDropdownItem routerLink="./dentist">
                        <clr-icon shape="first-aid"></clr-icon>
                        Zahnärzte
                    </a>
                    <a clrDropdownItem routerLink="./technicians">
                        <clr-icon shape="user"></clr-icon>
                        Techniker
                    </a>
                    <a clrDropdownItem routerLink="./labs">
                        <clr-icon shape="users"></clr-icon>
                        Labore
                    </a>
                    <a (click)="logOut()" clrDropdownItem>
                        <clr-icon shape="logout"></clr-icon>
                        Abmelden
                    </a>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </header>
    <div class="content-container">
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
        <clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed">
            <button *ngIf="!collapsed" clrVerticalNavLink routerLink="./addTask" style="color: whitesmoke;" type="button" class="btn btn-icon btn-primary" aria-label="settings">
                <clr-icon shape="plus-circle"></clr-icon>
                Auftragserfassung
            </button>
            <a clrVerticalNavLink *ngIf="collapsed">
                <clr-icon clrVerticalNavIcon shape="plus-circle"></clr-icon>
                Auftragserfassung
            </a>
            <a clrVerticalNavLink routerLink="./home" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="home"></clr-icon>
                Übersicht
            </a>
            <a clrVerticalNavLink routerLink="./tasks" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="tasks"></clr-icon>
                Arbeiten
            </a>
            <a clrVerticalNavLink routerLink="./calender" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="calendar"></clr-icon>
                Kalender
            </a>
            <a routerLink="./calenderstatus" clrVerticalNavLink>
                <clr-icon shape="calendar"></clr-icon>
                Kalender-Einstellungen
            </a>
            <a routerLink="./charge" clrVerticalNavLink>
                <clr-icon shape="cog"></clr-icon>
                Chargen-Managment
            </a>
            <div class="nav-divider"></div>
            <full-calendar *ngIf="!collapsed" [options]="calendarOptions"></full-calendar>
        </clr-vertical-nav>
    </div>
</div>

<simple-notifications></simple-notifications>