import { Component, Input, OnInit } from '@angular/core';
import { Calendar } from '@fullcalendar/angular';
import { CalenderStatus } from '../models/calender-status.interface';
import { Task } from '../models/task.interface';
import { Technician } from '../models/technician.interface';
import { DataService } from '../services/data.service';
import { DateService } from '../services/date.service';

@Component({
  selector: 'app-task-view-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailViewComponent implements OnInit {
  @Input() taskDetail: Task;
  @Input() technicians: Technician[];

  constructor(
    private data: DataService,
    public dateService: DateService,
  ) { }

  ngOnInit(): void {
  }

  getInterDateString(date: Date): string {
    const newDate = new Date(date.toString());
    return this.dateService.internDateStringFormatter(newDate);
  }

  checkDate(date: Date): boolean {
    const current = new Date();
    const parse = new Date(date);
    if (current > parse) {
      return true;
    } else {
      return false;
    }
  }

    getTechnician(id: number): Technician {
      const found = this.technicians.find(v => v.id === +id);
      if (found) {
        return found;
      } else {
        return null;
      }
    }

}
