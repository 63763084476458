import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClrWizard } from '@clr/angular';
import { CalenderStatus } from 'src/app/models/calender-status.interface';
import { Dentist } from 'src/app/models/dentist.interface';
import { TaskDate } from 'src/app/models/taskDate.interface';
import { Technician } from 'src/app/models/technician.interface';
import { DataService } from 'src/app/services/data.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-task-managment',
  templateUrl: './task-managment.component.html',
  styleUrls: ['./task-managment.component.scss'],
})
export class TaskManagementComponent implements OnInit {
  @ViewChild('wizardlg') wizardLarge: ClrWizard;

  dates: TaskDate[] = [];

  calenderStatus: CalenderStatus[] = [];
  technicians: Technician[] = [];
  dentists: Dentist[] = [];

  selectedTechnician: Technician;
  selectedDentist: Dentist;
  selectedCalenderStatus: number;
  dateDescription = '';
  selectedDate: string;
  errorModal = false;
  allowEmpty = false;

  open = true;

  model: any;

  minutes = '00';

  hours = '08';

  result: Date;

  constructor(
    private data: DataService,
    private router: Router,
    public dateService: DateService
  ) {}

  ngOnInit(): void {
    this.data.refreshCalenderStatus();
    this.data.currentCalenderStatus.subscribe((v) => {
      this.calenderStatus = v;
      if (v.length > 0) {
        this.selectedCalenderStatus = v[0].id;
      }
    });
    this.data.refreshTechnicians();
    this.data.currentTechnicians.subscribe((v) => {
      this.technicians = v;
      if (v.length > 0) {
        this.selectedTechnician = v[0];
      }
    });
    this.data.refreshDentists();
    this.data.currentDentists.subscribe((v) => (this.dentists = v));

    this.model = {
      uid: '',
      description: '',
      patient: '',
      technician: null,
      dentistId: null,
    };
  }

  getInterDateString(date: Date): string {
    const newDate = new Date(date.toString());
    return this.dateService.internDateStringFormatter(newDate);
  }
  getTimeString(): string {
    return this.dateService.getTimeString(this.hours, this.minutes);
  }

  getCalenderStatusById(id: number): CalenderStatus {
    return this.calenderStatus.find((v) => v.id === +id);
  }

  getRandomInt(max): number {
    return Math.floor(Math.random() * Math.floor(max));
  }

  checkButton(): boolean {
    if (this.allowEmpty || this.dates.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  addDateToList(): void {
    this.calculateDate();
    const milliseconds = new Date().getTime();
    const date: TaskDate = {
      id: milliseconds,
      date: this.result,
      taskId: null,
      labOwnerId: null,
      calenderStatusId: this.selectedCalenderStatus,
      calenderStatus: this.getCalenderStatusById(this.selectedCalenderStatus),
    };
    this.dates.push(date);
  }

  removeFromDateList(id: number): void {
    const index = this.dates.findIndex((v) => v.id === +id);
    if (index > -1) {
      this.dates.splice(index, 1);
    }
  }
  finishCheck(): void {
    if (this.dates.length !== 0) {
      this.doFinish();
    } else {
      this.errorModal = true;
    }
  }

  async doFinish(): Promise<void> {
    // tslint:disable-next-line: max-line-length
    const newTask = await this.data.createTask(
      this.createDatextString(this.model.uid),
      this.model.description,
      this.model.patient,
      this.selectedDentist.id,
      this.selectedTechnician.id
    );

    this.dates.forEach(async (e) => {
      const newDate = new Date(e.date);

      await this.data.createTaskDate(newDate, newTask.id, e.calenderStatus.id);
    });
    this.doReset();
    await this.router.navigate(['/app/calender']);
  }

  async testNavigate(): Promise<void> {
    await this.router.navigate(['/app/calender']);
  }

  calculateDate(): void {
    this.result = this.dateService.dateFromHourMinutesDate(
      +this.hours,
      +this.minutes,
      this.selectedDate
    );
  }

  // 202000002587
  // 202000002134
  createDatextString(datextId: number): string {
    const year = new Date().getFullYear();
    const datextStyleNumber = parseInt(`${year}00000000`, 10);
    const num = datextStyleNumber + datextId;
    return num.toString();
  }

  doReset(): void {
    this.model.description = '';
    this.model.patient = '';
    this.model.technicianId = null;
    this.model.dentistId = null;
    this.dates = [];
  }
}
