
<table class="table table-noborder">
    <thead>
        <tr>
            <td><img src="{{ getTechnician(taskDetail.taskOwnerId ).profileImageUrl }}" alt="Bild" style="
                                vertical-align: middle;
                                width: 70px;
                                height: 70px;
                                border-radius: 50%;
                                "></td>

            <td>
                <h3>{{ getTechnician(taskDetail.taskOwnerId ).firstName}}
                    {{ getTechnician(taskDetail.taskOwnerId).lastName}} -
                    {{getTechnician(taskDetail.taskOwnerId ).technicianId}}</h3>
            </td>
        </tr>
    </thead>
</table>
<hr style="color: rgb(194, 194, 194);">
<h3>
    <clr-icon shape="user" size="24"></clr-icon> {{ taskDetail.patient }}
</h3>
<h4>
    <clr-icon shape="first-aid" size="24"></clr-icon> {{ taskDetail.dentist.name }}
</h4>
<h4>{{ taskDetail.description }}</h4>
<hr style="color: rgb(194, 194, 194);">
<p>
    <clr-icon shape="calendar" size="16"></clr-icon> Erstellt am: {{ taskDetail.createdAt | date:'medium' }}
</p>
<h4>Termine:</h4>
<table class="table table-noborder">
    <thead>
        <tr>
            <th>Farbe</th>
            <th class="left">Termin</th>
            <th class="left">Datum</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of taskDetail.dates">
            <td>
                <div [ngStyle]="{'background': item.calenderStatus.color}"
                    style="border-radius: 7px; width: 14px; height: 14px; margin-top: 5px; margin-left: 15px;">
                </div>
            </td>
            <td class="left"> {{ item.calenderStatus.statusDescription}}</td>
            <td class="left"><div class="clr-col" >Final: {{item.date | date:'medium'}}<strong></strong><br>Intern: {{getInterDateString(item.date)}}</div></td>


            <td *ngIf="checkDate(item.date)" class="center">
                <clr-icon shape="check"></clr-icon>
            </td>
        </tr>
    </tbody>
</table>