import { Component, OnInit } from '@angular/core';
import { CalenderStatus } from 'src/app/models/calender-status.interface';
import { Dentist } from 'src/app/models/dentist.interface';
import { Lab } from 'src/app/models/lab.interface';
import { TaskStatus } from 'src/app/models/task-status.enum';
import { Task } from 'src/app/models/task.interface';
import { TaskDate } from 'src/app/models/taskDate.interface';
import { Technician } from 'src/app/models/technician.interface';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent  {
/*
  calenderStatuses: CalenderStatus[] = [];
  calenderStatusDetail: CalenderStatus;

  taskDates: TaskDate[] = [];
  taskDateDetail: TaskDate;

  tasks: Task[] = [];
  tasksDetail: Task;

  dentists: Dentist[] = [];
  dentistDetail: Dentist;

  technicians: Technician[] = [];
  technicianDetail: Technician;

  labs: Lab[] = [];
  labDetail: Lab;

  constructor(
    private restService: RestService
  ) { }

  ngOnInit(): void {
    this.getCalenderStatuses().then();
    this.getDentists().then();
    this.getTasks().then();
    this.getTechnicians().then();
    this.getLabs().then();
  }

  // RestService CalenderStatus

  async getCalenderStatuses(): Promise<void> {
    this.calenderStatuses = await this.restService.getCalenderStatus();
  }

  async updateCalenderStatus(calenderStatus: CalenderStatus): Promise<void> {
    await this.restService.updateCalenderStatus(calenderStatus);
    this.getCalenderStatuses().then();
  }

  async createCalenderStatus(statusDescription: string, color: string): Promise<void> {
    // await this.restService.createCalenderStatus(statusDescription, color);
    this.getCalenderStatuses().then();
  }

  // RestService Dentist

  async getDentists(): Promise<void> {
    this.dentists = await this.restService.getDentists2();
  }

  async updateDentist(dentist: Dentist): Promise<void> {
    await this.restService.updateDentist2(dentist);
    this.getDentists().then();
  }

  async createDentist(name: string, location: string): Promise<void> {
    await this.restService.createDentist2(name, location);
    this.getDentists().then();
  }

  // RestService Technicians

  async getTechnicians(): Promise<void> {
    this.technicians = await this.restService.getTechnicians2();
  }

  async updateTechnician(technician: Technician): Promise<void> {
    await this.restService.updateTechnicians2(technician);
    this.getTechnicians().then();
  }

  async createTechnician(
    technicianId: string, firstName: string, profileImageUrl: string, lastName: string, labId: number
  ): Promise<void> {
    await this.restService.createTechnicians2(technicianId, firstName, profileImageUrl, lastName, labId);
    this.getTechnicians().then();
  }

  // RestService TaskDates

  async getTaskDates(): Promise<void> {
    this.taskDates = await this.restService.getTaskDates().then();
  }

  async updateTaskDate(taskDate: TaskDate): Promise<void> {
    await this.restService.updateTaskDate(taskDate);
    this.getTaskDates().then();
  }

  async createTaskDate(date: Date, taskId: number, calenderStatusId: number): Promise<void> {
    await this.restService.createTaskDate(date, taskId, calenderStatusId);
    this.getTaskDates();
  }

  // RestService Tasks

  async getTasks(): Promise<void> {
    this.tasks = await this.restService.getTasksByStatus(TaskStatus.ACTIVE);
  }

  async updateTask(task: Task): Promise<void> {
    await this.restService.updateTask(task);
    this.getTasks();
  }

  async createTask(uid: string, description: string, patient: string, dentistId: number, technicianId: number): Promise<void> {
    await this.restService.createTask(uid, description, patient, +dentistId, +technicianId);
    this.getTasks();
  }

  // RestService Labs

  async getLabs(): Promise<void> {
    this.labs = await this.restService.getLabs2();
  }

  async updateLab(lab: Lab): Promise<void> {
    await this.restService.updateLab2(lab);
    this.getLabs();
  }

  async createLab(labName: string): Promise<void> {
    await this.restService.createLab2(labName);
    this.getLabs();
  }*/

}
