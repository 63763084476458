import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { HeaderComponent } from './layout/header/header.component';
import { TechnicianContentComponent } from './content/technician-content/technician-content.component';
import { DentistContentComponent } from './content/dentist-content/dentist-content.component';
import { TaskContentComponent } from './content/task-content/task-content.component';
import { CalenderContentComponent } from './content/calender-content/calender-content.component';
import { LabContentComponent } from './content/lab-content/lab-content.component';
import { HttpClientModule } from '@angular/common/http';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { UserSettingsComponent } from './settings/user-settings/user-settings.component';
import { FormsModule } from '@angular/forms';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@somebodyodd/angular-datetime-picker';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { CalenderStatusContentComponent } from './content/calender-status-content/calender-status-content.component';
import { TaskManagementComponent } from './content/task-managment/task-managment.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { TaskDetailViewComponent } from './task-detail/task-detail.component';
import { ChargeComponent } from './charge/charge.component';
import { QRCodeSVGModule } from 'ngx-qrcode-svg';
import { CdsModule } from '@cds/angular';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import {HighlightSearch} from './pipes/highlight.pipe';

FullCalendarModule.registerPlugins([
  listPlugin,
  timeGridPlugin,
  dayGridPlugin,
  interactionPlugin,
]);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    TechnicianContentComponent,
    DentistContentComponent,
    TaskContentComponent,
    HighlightSearch,
    CalenderContentComponent,
    LabContentComponent,
    SidenavComponent,
    DashboardComponent,
    UserSettingsComponent,
    CalenderStatusContentComponent,
    TaskManagementComponent,
    TaskDetailViewComponent,
    ChargeComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ClarityModule,
    BrowserAnimationsModule,
    FormsModule,
    FullCalendarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    KeyboardShortcutsModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    QRCodeSVGModule,
    CdsModule,
    NgxPopperjsModule,
  ],
  providers: [
    // { provide: LOCALE_ID, useValue: 'de' },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'de' },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
