export enum UpdateCategory {
    BATCHES = 'BATCHES',
    BATCHPRODUCTS = 'BATCHPRODUCTS',
    CALENDERSTATUS = 'CALENDERSTATUS',
    DATES = 'DATES',
    DENTISTS = 'DENTISTS',
    LABS = 'LABS',
    TASKS = 'TASKS',
    TECHNICIAN = 'TECHNICIAN',
}

export enum CRUD {
    GET = 'GET',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
}
