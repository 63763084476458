import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.interface';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import * as io from 'socket.io-client';
import { CalenderStatus } from 'src/app/models/calender-status.interface';
import { TaskDate } from 'src/app/models/taskDate.interface';
import { LocationTaskNumbers, Task } from 'src/app/models/task.interface';
import { Dentist } from 'src/app/models/dentist.interface';
import { Technician } from 'src/app/models/technician.interface';
import { Lab } from 'src/app/models/lab.interface';
import { TechnicianStatus } from 'src/app/models/technician-status.enum';
import { TaskLocationStatus } from 'src/app/models/task-status.enum';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  calenderStatuses: CalenderStatus[] = [];
  calenderStatusDetail: CalenderStatus;

  taskDates: TaskDate[] = [];
  taskDateDetail: TaskDate;

  tasks: Task[] = [];
  tasksDetail: Task;

  dentists: Dentist[] = [];
  dentistDetail: Dentist;

  technicians: Technician[] = [];
  technicianDetail: Technician;

  labs: Lab[] = [];
  labDetail: Lab;

  detailModal = false;
  detailData: Technician;

  constructor(private data: DataService, private dateService: DateService) {}

  getInterDateString(date: Date): string {
    const newDate = new Date(date.toString());
    return this.dateService.internDateStringFormatter(newDate);
  }

  openDetailModal(technicianId: number): void {
    this.detailData = this.technicians.find((t) => t.id === +technicianId);
    this.detailModal = true;
  }

  getTasksLocationNumberByTechnicianId(id: number): LocationTaskNumbers {
    const tasks = this.tasks.filter((t) => t.taskOwnerId === +id);
    return {
      inhouse: tasks.filter(
        (t) => t.locationStatus === TaskLocationStatus.INHOUSE
      ).length,
      notinhouse: tasks.filter(
        (t) => t.locationStatus === TaskLocationStatus.NOTINHOUSE
      ).length,
      dentist: tasks.filter(
        (t) => t.locationStatus === TaskLocationStatus.DENTIST
      ).length,
    } as LocationTaskNumbers;
  }

  getTasksNumberByTechnicianId(id: number): number {
    return this.tasks.filter((t) => t.taskOwnerId === +id).length;
  }

  /*   getTechnicianLengthByLabId(id: number): number {
    return this.technicians.filter((t) => t.labId === +id).length;
  } */

  getTechnicianLengthByLabId(id: number): number {
    return this.technicians
      .filter((t) => t.labId === +id)
      .map((v) => v.job)
      .reduce((a, b) => +a + +b);
  }

  getTasksByTechnicianId(id: number): Task[] {
    return this.tasks.filter((t) => t.taskOwnerId === +id);
  }

  getTaskDatesByTaskID(taskId: number): TaskDate[] {
    return this.taskDates.filter((d) => d.taskId === +taskId);
  }

  getTasksByLab(technicians: Technician[]): number {
    const technicianIdsLab: number[] = [0, 0];
    technicians.forEach((v) => {
      technicianIdsLab.push(this.getTasksNumberByTechnicianId(v.id));
    });
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return technicianIdsLab.reduce(reducer);
  }

  checkDate(date: Date): boolean {
    const current = new Date();
    const parse = new Date(date);
    if (current > parse) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    this.data.refreshCalenderStatus();
    this.data.currentCalenderStatus.subscribe(
      (v) => (this.calenderStatuses = v)
    );

    this.data.refreshTaskDates();
    this.data.currentTaskDate.subscribe((v) => (this.taskDates = v));

    this.data.refreshTasks();
    this.data.currentTasks.subscribe((v) => (this.tasks = v));

    this.data.refreshDentists();
    this.data.currentDentists.subscribe((v) => (this.dentists = v));

    this.data.refreshTechnicians();
    this.data.currentTechnicians.subscribe((v) => {
      const technicians = [];
      v.forEach((x) => {
        if (x.status === TechnicianStatus.ACTIVE) {
          technicians.push(x);
        }
      });
      this.technicians = technicians;
    });

    this.data.refreshLabs();
    this.data.currentLabs.subscribe((v) => (this.labs = v));
  }
}
