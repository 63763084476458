<div class="login-wrapper">
    <div class="login">
        <section class="title">
            <h3 class="welcome">Willkommen bei deinem</h3>
            Dental Lab Manager
            <h5 class="hint">Melden Sie sich mit Ihren Zugangsdaten an</h5>
        </section>
        <div class="login-group">
            <clr-input-container>
                <label class="clr-sr-only">Email</label>
                <input type="email" name="username" clrInput placeholder="Email" [(ngModel)]="email" />
            </clr-input-container>
            <clr-password-container>
                <label class="clr-sr-only">Passwort</label>
                <input type="password" name="password" clrPassword placeholder="Passwort" [(ngModel)]="password"/>
            </clr-password-container>
            <clr-checkbox-wrapper>
                <label>Anmeldedaten merken</label>
                <input type="checkbox" name="rememberMe" clrCheckbox />
            </clr-checkbox-wrapper>
            <div *ngIf="error" class="error active">
                Email oder Password ungültig!
            </div>
            <button (click)="signIn()" class="btn btn-primary">ANMELDEN</button>
            <a routerLink='/register' class="signup">Account Erstellung</a>
        </div>
    </div>
</div>