import { Component, OnInit } from '@angular/core';
import { addDays, parse } from 'date-fns';
import { CalenderStatus } from 'src/app/models/calender-status.interface';
import { CalenderDateRange } from 'src/app/models/calenderDate.interface';
import { RRuleFreq, RRuleOptions } from 'src/app/models/rRule-interface';
import { DataService } from 'src/app/services/data.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-calender-status-content',
  templateUrl: './calender-status-content.component.html',
  styleUrls: ['./calender-status-content.component.scss'],
})
export class CalenderStatusContentComponent implements OnInit {
  calenderStatus: CalenderStatus[] = [];
  calenderDateRange: CalenderDateRange[] = [];

  addModal: boolean;
  pageSize = 10;
  description = '-';
  pageIndex: number;
  pageSizeDate = 10;
  pageIndexDate: number;
  patchModal: boolean;
  calenderAddModal: boolean;
  calenderPatchModal: boolean;
  detailData: CalenderStatus;
  newCalenderStatusData: CalenderStatus = {
    id: 0,
    color: '#d14c43',
    fontColor: '#f7eeed',
    statusDescription: '',
  };
  rRuleForm = false;
  rRuleOptions: RRuleOptions = {
    freq: RRuleFreq.EMPTY,
    count: 0,
    interval: 1,
  };
  detailDataCalender: CalenderDateRange;
  toDate = new Date();
  fromDate = new Date();

  constructor(private helper: HelperService, private data: DataService) {}

  ngOnInit(): void {
    this.data.refreshCalenderStatus();
    this.data.currentCalenderStatus.subscribe((v) => (this.calenderStatus = v));
    this.data.refreshCalenderDateRange();
    this.data.currentCalenderDateRanges.subscribe(
      (v) => (this.calenderDateRange = v)
    );
  }

  updateFromDate(e: Date): void {
    this.detailDataCalender.from = e;
  }

  updateToDate(e: Date): void {
    this.detailDataCalender.to = e;
  }

  convertDate(date: any): Date {
    return new Date(date);
  }

  async updateCalenderStatus(): Promise<void> {
    await this.data.updateCalenderStatus(this.detailData);
    this.detailData = null;
  }

  async deleteCalenderStatus(id: number): Promise<void> {
    await this.data.deleteCalenderStatus(+id);
    this.detailData = null;
    this.patchModal = false;
  }

  openPatchModal(calenderStatus: CalenderStatus): void {
    this.detailData = calenderStatus;
    this.patchModal = true;
  }

  async createCalenderStatus(): Promise<void> {
    const { statusDescription, color, fontColor } = this.newCalenderStatusData;
    await this.data.createCalenderStatus(statusDescription, color, fontColor);
    this.addModal = false;
  }

  async updateCalenderDate(): Promise<void> {
    const { id, color, description, to, from, notes } = this.detailDataCalender;

    const newRule =
      this.rRuleOptions.freq !== RRuleFreq.EMPTY && this.rRuleForm
        ? this.rRuleStringBuilder(this.rRuleOptions)
        : 'null';
    const newDate: CalenderDateRange = {
      notes,
      id,
      rRule: newRule,
      from: this.fromDate, // parse(from.toString(), 'd.MM.yyyy', new Date()),
      to: this.toDate, // parse(to.toString(), 'd.MM.yyyy', new Date()),
      color,
      description,
    };
    await this.data.updateCalenderDateRange(newDate);
    this.detailDataCalender = null;
  }

  openCalenderDatePatchModal(calenderDateRange: CalenderDateRange): void {
    if (calenderDateRange.rRule) {
      this.rRuleOptions = this.rRuleOptionsBuilder(calenderDateRange.rRule);
      this.rRuleForm = true;
    } else {
      this.rRuleForm = false;
    }
    this.toDate = new Date(calenderDateRange.to);
    this.fromDate = new Date(calenderDateRange.from);
    this.detailDataCalender = calenderDateRange;
    this.calenderPatchModal = true;
  }
  async deleteCalenderDate(id: number): Promise<void> {
    await this.data.deleteCalenderDateRange(+id);
    this.detailDataCalender = null;
    this.calenderPatchModal = false;
  }

  async createCalenderRangeDate(
    from: string,
    to: string,
    color: string,
    description: string
  ): Promise<void> {
    const rRule = this.rRuleStringBuilder(this.rRuleOptions);
    console.log(this.description);
    await this.data.createCalenderDateRange(
      from,
      to,
      color,
      description,
      rRule,
      this.description
    );
    this.calenderAddModal = false;
  }

  rRuleStringToText(rRuleString: string): string {
    if (!rRuleString) {
      return 'keine Regel';
    }
    const options = this.rRuleOptionsBuilder(rRuleString);
    return this.rRuleOptionsToText(options);
  }

  rRuleStringBuilder(rRuleOptions: RRuleOptions): string {
    if (
      rRuleOptions.freq === RRuleFreq.EMPTY ||
      rRuleOptions.count === 0 ||
      rRuleOptions.interval === 0
    ) {
      return 'null';
    }
    return this.helper.rRuleStringBuilder(rRuleOptions);
  }

  rRuleOptionsBuilder(rRuleString: string): RRuleOptions {
    return this.helper.rRuleOptionsBuilder(rRuleString);
  }

  rRuleFreqEnumToText(freqEnum: RRuleFreq): string {
    return this.helper.rRuleFreqEnumToText(freqEnum);
  }

  rRuleFreqEnumToTextTimeSingular(freqEnum: RRuleFreq): string {
    return this.helper.rRuleFreqEnumToTextTimeSingular(freqEnum);
  }

  rRuleFreqEnumToTextTimePlural(freqEnum: RRuleFreq): string {
    return this.helper.rRuleFreqEnumToTextTimePlural(freqEnum);
  }

  rRuleOptionsToText(rRuleOptions: RRuleOptions): string {
    return this.helper.rRuleOptionsToText(rRuleOptions);
  }
}
